export type ThresholdOption = {
	name: string;
	value: number;
	hidden?: boolean;
};

export const THRESHOLD_OPTIONS: ThresholdOption[] = [
	{
		name: "Basic",
		value: 1 / 75,
	},
	{
		name: "Starter",
		value: 1 / 100,
	},
	{
		name: "Intermediate",
		value: 1 / 150,
	},
	{
		name: "Advanced",
		value: 1 / 200,
	},
	{
		name: "Tournament ready",
		value: 1 / 300,
	},
	{
		name: "Bulletproof",
		value: 1 / 400,
	},
	{
		name: "Overkill",
		value: 1 / 600,
		hidden: true,
	},
	{
		name: "Extreme overkill",
		value: 1 / 800,
		hidden: true,
	},
];
