import { RateApp } from "capacitor-rate-app";
import { forEach, mapValues } from "lodash-es";
import { For, type JSXElement, Show, createMemo, onMount } from "solid-js";
import type { Side } from "~/types/Side";
import type { EnrichedComponent } from "~/types/View";
import { APP_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { isNative } from "~/utils/env";
import { pluralize } from "~/utils/pluralize";
import { registerViewMode } from "~/utils/register_view_mode";
import { trackEvent } from "~/utils/trackEvent";
import { Bullet } from "./Bullet";
import { CMText } from "./CMText";
import { showerSuccessConfetti } from "./Confetti";
import { ConnectAccountsSetting } from "./ConnectedAccounts";
import { LoginSidebar } from "./LoginSidebar";
import { ReviewText, getHumanTimeUntil } from "./ReviewText";
import { type SidebarAction, useBiggestGapActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const PracticeComplete: EnrichedComponent<null> = () => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const allReviewPositionMoves = () => REPERTOIRE_STATE().reviewState.allReviewPositionMoves;
	const moves = createMemo(() => {
		const moves: {
			epd: string;
			sanPlus: string;
			failed: boolean;
			side: Side;
		}[] = [];
		forEach(allReviewPositionMoves(), (sanLookup, epd) => {
			forEach(sanLookup, ({ failed, side }, sanPlus) => {
				moves.push({ epd, sanPlus, failed, side });
			});
		});
		return moves;
	});
	const activeOptions = () => APP_STATE().repertoireState.reviewState.activeOptions!;
	const numFailed = () => {
		return moves().filter((m) => m.failed).length;
	};
	const numCorrect = () => {
		return moves().filter((m) => !m.failed).length;
	};
	const total = () => {
		return moves().length;
	};
	const activeRepertoire = () =>
		activeOptions().repertoireId
			? REPERTOIRE_STATE().repertoires?.[activeOptions().repertoireId!]
			: null;
	const overallEarliest = createMemo(() => {
		return REPERTOIRE_STATE().getEarliestDue();
	});
	const totalDue = () => REPERTOIRE_STATE().totalMovesDue ?? 0;
	onMount(() => {
		if (deservesConfetti()) {
			showerSuccessConfetti();
		}
	});
	onMount(() => {
		trackEvent("practice_complete", {
			num_failed: numFailed(),
			num_correct: numCorrect(),
		});

		if (Math.random() < 0.25 && APP_STATE().userState.user?.subscribed && isNative) {
			trackEvent("prompted_for_app_review");
			RateApp.requestReview();
		}
	});
	const numMovesDueByRepertoire = () => {
		return mapValues(REPERTOIRE_STATE().getStandardAndSuperRepertoires(), (repertoire) => {
			return REPERTOIRE_STATE().numMovesDueFromEpd[repertoire.id]?.[START_EPD] ?? 0;
		});
	};
	const due = () => {
		const due = activeRepertoire() ? numMovesDueByRepertoire()[activeRepertoire()!.id] : totalDue();
		return due;
	};
	const earlyQueue = createMemo(() => {
		if (due() === 0 && !onboarding()) {
			return APP_STATE().repertoireState.reviewState.buildQueue({
				repertoireId: activeOptions()?.repertoireId,
				filter: "early",
			});
		}
		return null;
	});
	const practicedMistakes = () => activeOptions()?.lichessMistakes;
	const deservesConfetti = () => total() > 0 && total() === numCorrect() && !practicedMistakes();
	const commonQueue = createMemo(() => {
		return APP_STATE().repertoireState.reviewState.buildQueue({
			repertoireId: activeOptions()?.repertoireId,
			filter: "recommended",
		});
	});

	const actions = () => {
		const actions: SidebarAction[] = [];
		if (onboarding().isOnboarding) {
			return [
				{
					onPress: () => {
						quick((s) => {
							if (s.repertoireState.onboarding.isOnboarding) {
								trackEvent("onboarding.practice_complete.continue");
								s.repertoireState.updateRepertoireStructures();
								if (s.userState.isConnectedToExternal()) {
									UI().pushView(LoginSidebar, {
										props: { authType: "register" },
									});
								} else {
									UI().pushView(ConnectAccountsSetting);
								}
							}
						});
					},
					text: "Continue",
					style: actions.length > 0 ? "secondary" : "primary",
				} as SidebarAction,
			];
		}
		if (due() > 0 && activeOptions()?.filter === "recommended") {
			actions.push({
				onPress: () => {
					quick((s) => {
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: activeOptions()?.repertoireId,
							filter: "recommended",
						});
						trackEvent("post_review.next_common_moves");
					});
				},
				text: "Practice next recommended moves",
				right: <ReviewText numDue={commonQueue()?.length ?? 0} />,
				style: "secondary",
			});
		}
		const early = earlyQueue();
		if (early) {
			actions.push({
				onPress: () => {
					quick((s) => {
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: activeOptions()?.repertoireId,
							filter: "early",
						});
						trackEvent("post_review.next_common_moves");
					});
				},
				text: `Review your next ${pluralize(early.length, "move")} moves early`,
				right: null,
				style: "secondary",
			});
		}
		const actionsStyle = actions.length > 0 ? "secondary" : "primary";
		const [nearestGapAction] = useBiggestGapActions({ source: "nearest" });
		if (
			nearestGapAction &&
			!onboarding().isOnboarding &&
			activeOptions().cameFrom === "review_specific_line"
		) {
			actions.push({
				...nearestGapAction,
				style: actionsStyle,
				text: "Keep building your repertoire",
			});
		} else {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("practice_complete.continue");
						animateSidebar("left");
						s.repertoireState.backToOverview();
					});
				},
				text: "Continue",
				style: actionsStyle,
			});
		}
		if (!practicedMistakes()) {
			actions.push({
				onPress: () => {
					quick((s) => {
						UI().cutView();
						trackEvent("practice_complete.again");
						animateSidebar("right");
						s.repertoireState.reviewState.startReview({
							repertoireId: activeOptions()?.repertoireId,
							customQueue: s.repertoireState.reviewState.originalQueue!,
						});
					});
				},
				text: "Practice these moves again",
				style: actionsStyle,
			});
		}

		return actions;
	};
	const bullets = () => {
		const totalDue = REPERTOIRE_STATE().totalMovesDue ?? 0;
		const bullets: JSXElement[] = [];
		bullets.push(
			<>
				You practiced{" "}
				<span class={clsx("text-highlight font-semibold")}>{pluralize(total(), "move")}</span>
			</>,
		);
		if (deservesConfetti()) {
			bullets.push(
				<>
					You got <span class={clsx("rainbow-shine font-bold")}>100%</span> of your moves right
				</>,
			);
		} else {
			bullets.push(
				<>
					You played the correct move{" "}
					<span class={clsx("text-highlight font-semibold")}>
						{pluralize(numCorrect(), "time")}
					</span>{" "}
					<span>({Math.round((100 * numCorrect()) / total())}%)</span>
				</>,
			);
		}
		if (totalDue > 0) {
			bullets.push(
				<>
					You have{" "}
					<span class={clsx("text-highlight font-semibold")}>{pluralize(totalDue, "move")}</span>{" "}
					due for review now
				</>,
			);
		} else if (overallEarliest()) {
			bullets.push(
				<>
					Your next practice will be due in{" "}
					<span class={clsx("text-highlight font-semibold")}>
						{getHumanTimeUntil(new Date(overallEarliest()!))}
					</span>
				</>,
			);
		}
		return bullets;
	};
	return (
		<SidebarTemplate
			header={practicedMistakes() ? "Done reviewing mistakes" : "Practice complete!"}
			bodyPadding={true}
			actions={actions()}
		>
			<Show when={!practicedMistakes()}>
				<CMText class={clsx("text-primay font-bold")}>Your stats from this session:</CMText>
				<Spacer between={["body-text", "bullets"]} />
				<div class={"space-y-2"}>
					<For each={bullets()}>{(bullet) => <Bullet>{bullet}</Bullet>}</For>
				</div>
			</Show>
			<Show when={practicedMistakes()}>
				<CMText class={clsx("body-text")}>
					You're done reviewing your games for mistakes in the opening! Go play more games, or
					review your repertoire so you won't make these mistakes again.
				</CMText>
			</Show>
		</SidebarTemplate>
	);
};

registerViewMode(PracticeComplete, "review");
