import { Chess } from "@lubert/chess.ts";
import type { Move } from "@lubert/chess.ts/dist/types";
import * as Sentry from "@sentry/browser";
import _, { drop, sum } from "lodash-es";
import {
	cloneDeep,
	every,
	filter,
	find,
	flatten,
	forEach,
	includes,
	isEmpty,
	isNil,
	last,
	map,
	mapValues,
	maxBy,
	merge,
	noop,
	some,
	take,
	uniqBy,
	values,
	zip,
} from "lodash-es";
import { type JSXElement, createSignal } from "solid-js";
import { PreCourseImport } from "~/components/PreCourseImport";
import { RepertoireBuilder } from "~/components/RepertoireBuilder";
import { RepertoireCheckInView } from "~/components/RepertoireCheckInView";
import { animateSidebar } from "~/components/SidebarContainer";
import { PracticeIntroOnboarding } from "~/components/SidebarOnboarding";
import { TargetCoverageReachedView } from "~/components/TargetCoverageReachedView";
import { UpgradeSubscriptionView } from "~/components/UpgradeSubscriptionView";
import type { CourseDetailsDTO, CourseOverviewDTO, ImportCourseBehavior } from "~/rspc";
import { Course } from "~/types/Course";
import { EcoCode } from "~/types/EcoCode";
import { Epd } from "~/types/Epd";
import { GameResultsDistribution } from "~/types/GameResults";
import { Line } from "~/types/Line";
import { MoveRating } from "~/types/MoveRating";
import { MoveTag } from "~/types/MoveTag";
import type { PositionReport } from "~/types/PositionReport";
import { type ByRepertoireId, Repertoire } from "~/types/Repertoire";
import type { RepertoireMiss } from "~/types/RepertoireGrade";
import type { RepertoireMove } from "~/types/RepertoireMove";
import { Side } from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import { StockfishEval } from "~/types/StockfishEval";
import type { StockfishReport } from "~/types/StockfishReport";
import { SuggestedMove } from "~/types/SuggestedMove";
import type { TableResponse } from "~/types/TableResponse";
import type { Uuid } from "~/types/Uuid";
import { trackEvent } from "~/utils/trackEvent";
import { APP_STATE, type AppState, UI, USER_STATE } from "./app_state";
import { START_EPD, genEpd, sideFromEpd } from "./chess";
import { type ChessboardInterface, createChessboardInterface } from "./chessboard_interface";
import client from "./client";
import { getCoverageProgress } from "./coverage_estimation";
import { isDevelopment } from "./env";
import { getMoveRating } from "./move_inaccuracy";
import { PAYMENT_ENABLED } from "./payment";
import { parsePlans } from "./plans";
import { createQuick } from "./quick";
import type { FetchRepertoireResponse, RepertoireState } from "./repertoire_state";
import { rspcClient } from "./rspc_client";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import { Stockfish } from "./stockfish";
import { scoreTableResponses, shouldUsePeerRates } from "./table_scoring";
import { isTheoryHeavy } from "./theory_heavy";

export enum SidebarOnboardingImportType {
	LichessUsername = "lichess_username",
	ChesscomUsername = "chesscom_username",
	PGN = "pgn",
	PlayerTemplates = "player_templates",
}

export interface BrowsingState {
	// Functions
	fetchNeededPositionReports: () => void;
	updateRepertoireProgress: () => void;
	finishSidebarOnboarding: () => void;
	getIncidenceOfCurrentLine: () => number;
	getLineIncidences: () => number[];
	getNearestMiss: () => RepertoireMiss | null;
	getBiggestMiss: () => RepertoireMiss | null;
	getMissInThisLine: () => RepertoireMiss | null;
	onPositionUpdate: () => void;
	updateTableResponses: () => void;
	requestToAddCurrentLine: () => void;
	quick: (fn: (_: BrowsingState) => void) => void;
	addPendingLine: () => Promise<void>;
	getOpeningNameForMove: (epdAfter: string) => string | null;
	updatePlans: () => void;
	checkShowTargetDepthReached: () => void;
	activeCourse: {
		overview: CourseOverviewDTO | undefined;
		details: CourseDetailsDTO | undefined;
	};
	goToBuildOnboarding(): unknown;
	reset: () => void;
	getActiveRepertoire: () => Repertoire | undefined;
	setActiveRepertoire: (repertoireId: Uuid | undefined) => void;
	startBrowsing: (
		repertoireId: Uuid,
		mode: "browse" | "build" | "plans_and_model_games",
		options?: {
			animated?: boolean;
			lineToPlay?: string[];
			import?: boolean;
			keepPosition?: boolean;
		},
	) => void;
	requestCourseImport: (opts: {
		course: CourseOverviewDTO;
		courseDetails: CourseDetailsDTO;
		fromEpd?: string;
	}) => void;
	importCourse: (opts: {
		course: CourseOverviewDTO;
		courseDetails: CourseDetailsDTO;
		importBehavior: ImportCourseBehavior;
		fromEpd?: string;
	}) => void;

	// Fields
	chessboard: ChessboardInterface;
	chessboardShown: boolean;
	repertoireProgressState: ByRepertoireId<RepertoireProgressState>;

	pendingPositionReports: Set<string>;

	// from sidebar state
	isPastCoverageGoal?: boolean;
	tableResponses: TableResponse[];
	hasAnyPendingResponses?: boolean;
	transposedState: Record<string, never>;
	showPlansState: {
		coverageReached: boolean;
		hasShown: boolean;
		autoShown: boolean;
	};
	deleteLineState: {
		move?: RepertoireMove;
	};
	addedLineState: {
		loading: boolean;
		justCompleted: boolean;
	};
	activeSide?: Side;
	activeRepertoireId?: Uuid;
	currentSide: Side;
	pendingResponses?: Record<string, PendingResponse>;
	hasPendingLineToAdd: boolean;
	lastEcoCode?: EcoCode;
	planSections?: (() => JSXElement)[];
}

export type PendingResponse = {
	sanPlus: string;
	epdAfter: string;
	epd: string;
	side: Side;
	mine: boolean;
};

export interface RepertoireProgressState {
	showNewProgressBar?: boolean;
	showPending?: boolean;
	completed: boolean;
	expectedDepth: number;
	showPopover: boolean;
	percentComplete: number;
	pendingMoves: number;
}

export interface BrowserLine {
	epd: string;
	pgn: string;
	ecoCode: EcoCode;
	line: string[];
	deleteMove: RepertoireMove;
}

export interface BrowserSection {
	lines: BrowserLine[];
	ecoCode: EcoCode;
}

type Stack = [BrowsingState, RepertoireState, AppState];

export const uiStateReset = () => {
	return {
		pendingPositionReports: new Set(),
		isPastCoverageGoal: false,
		tableResponses: [] as BrowsingState["tableResponses"],
		hasAnyPendingResponses: false,
		transposedState: {},
		showPlansState: {
			coverageReached: false,
			autoShown: false,
			hasShown: false,
		},
		deleteLineState: {},
		addedLineState: {
			loading: false,
			justCompleted: false,
		},
		pendingResponses: {},
		currentSide: "white",
		hasPendingLineToAdd: false,
	} as Partial<BrowsingState>;
};

export const getInitialBrowsingState = (
	_set: StateSetter<AppState, any>,
	_get: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _set((s) => fn([s.repertoireState.browsingState, s.repertoireState, s]));
	};
	const setOnly = <T,>(fn: (stack: BrowsingState) => T, _id?: string): T => {
		return _set((s) => fn(s.repertoireState.browsingState));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _get((s) => fn([s.repertoireState.browsingState, s.repertoireState, s]));
	};
	const initialState = {
		...createQuick(setOnly),
		...uiStateReset(),
		chessboard: undefined as unknown as ChessboardInterface,
		activeCourse: {
			overview: undefined,
			details: undefined,
		},
		activeSide: "white",
		hasPendingLineToAdd: false,
		chessboardShown: false,
		plans: {},
		repertoireProgressState: {},
		reset: () => {
			set(([s]) => {
				merge(s, uiStateReset());
			});
		},
		startBrowsing: (
			repertoireId: Uuid,
			_mode: "browse" | "build" | "plans_and_model_games",
			options?: {
				animated?: boolean;
				lineToPlay?: string[];
				import?: boolean;
				keepPosition?: boolean;
			},
		) =>
			set(([s, _gs]) => {
				s.showPlansState = {
					coverageReached: false,
					hasShown: false,
					autoShown: false,
				};
				if (repertoireId) {
					s.setActiveRepertoire(repertoireId);
				}
				if (options?.lineToPlay) {
					s.chessboard.playLine(options.lineToPlay, {
						animated: !isNil(options.animated) ? options.animated : true,
						reset: true,
					});
				}
			}, "startBrowsing"),
		setActiveRepertoire: (repertoireId: Uuid) =>
			set(([s]) => {
				s.activeRepertoireId = repertoireId;
				const repertoire = s.getActiveRepertoire();
				s.activeSide = repertoire?.side;
				s.isPastCoverageGoal = false;
				s.onPositionUpdate();
				s.chessboard.set((c) => {
					c.flipped = s.activeSide === "black";
				});
			}),
		getActiveRepertoire: () =>
			get(([s, rs]) => {
				const activeRepertoireId = s.activeRepertoireId;
				if (!activeRepertoireId) {
					// console.log("no active repertoire id", s.activeRepertoireId);
					return;
				}
				const repertoire = rs.repertoires?.[activeRepertoireId];
				return repertoire;
			}),
		requestCourseImport: (opts: {
			course: CourseOverviewDTO;
			courseDetails: CourseDetailsDTO;
			fromEpd: string;
		}) =>
			set(([_s, _rs]) => {
				UI().pushView(PreCourseImport, {
					props: { course: opts.course, courseDetails: opts.courseDetails, fromEpd: opts.fromEpd },
				});
			}),
		importCourse: (opts: {
			course: CourseOverviewDTO;
			courseDetails: CourseDetailsDTO;
			importBehavior: ImportCourseBehavior;
			fromEpd: string;
		}) =>
			set(([s, rs]) => {
				const subscribed = APP_STATE().userState.isSubscribed();
				let additionalMoves = Course.countMovesFrom(
					opts.courseDetails.responses,
					opts.fromEpd ?? START_EPD,
					s.getActiveRepertoire(),
				).totalMoves;

				if (!subscribed && rs.pastFreeTier(s.activeSide!, additionalMoves) && PAYMENT_ENABLED) {
					trackEvent("courses.saveToRepertoire.hitPaywall", {
						courseId: opts.course.id,
						courseName: opts.course.name,
					});
					UI().replaceView(UpgradeSubscriptionView, {
						props: { pastLimit: true },
					});
					return;
				}
				animateSidebar("right");
				UI().withoutAnimations(() => {
					UI().backTo(RepertoireBuilder);
				});
				const [loading, setLoading] = createSignal(true);
				UI().pushView(RepertoireCheckInView, {
					props: {
						loading: loading,
						justCompleted: () => false,
						addedFromCoursePair: {
							overview: opts.course,
							details: opts.courseDetails,
						},
					},
				});
				rspcClient
					.query([
						"openings.courses.importIntoRepertoire",
						{
							fromEpd: opts.fromEpd ?? START_EPD,
							repertoireId: s.activeRepertoireId!,
							id: opts.course.id,
							positionHistory: s.chessboard.get((s) => s.positionHistory),
							alternateBehavior: opts.importBehavior,
						},
					])
					.then((data) => {
						trackEvent("courses.importCourse.success", {
							courseId: opts.course.id,
							courseName: opts.course.name,
						});
						set(([s, rs]) => {
							rs.repertoireFetched(data);
							s.onPositionUpdate();
							rs.onRepertoireChange();
							s.activeCourse = {
								overview: undefined,
								details: undefined,
							};
							setLoading(false);
						});
					});
			}),
		goToBuildOnboarding: () =>
			set(([s, rs]) => {
				UI().clearViews();
				if (!rs.onboarding.side) {
					rs.onboarding.side = "white";
				}
				const side = rs.onboarding.side;
				const biggestMiss = find(rs.repertoireGrades, (grade) => grade.side === side)?.biggestMiss;
				if (!biggestMiss) {
					return;
				}
				const line = Line.fromPgn(biggestMiss.lines[0]);
				const repertoire = rs.getDefaultRepertoire(side);
				if (!repertoire) {
					return;
				}
				UI().clearViews();
				UI().pushView(RepertoireBuilder);
				s.startBrowsing(repertoire.id, "build", {
					lineToPlay: line,
				});
			}),
		updateRepertoireProgress: () =>
			set(([s, rs]) => {
				mapValues(rs.repertoires, (repertoire) => {
					const progressState = createEmptyRepertoireProgressState();
					const expectedDepth = Repertoire.getAllEnabledRepertoireMoves(repertoire).reduce(
						(expectedDepth, move) => {
							if (move.mine) {
								return expectedDepth + (move.incidence ?? 0);
							}
							return expectedDepth;
						},
						0,
					);
					progressState.expectedDepth = expectedDepth;
					const biggestMiss = rs.repertoireGrades[repertoire.id]?.biggestMiss;
					const numMoves = rs.numMovesNeededFromEpd?.[repertoire.id][START_EPD] ?? 0;
					// console.debug(
					// 	"Number of moves in repertoire",
					// 	repertoire.name,
					// 	filter(flatten(values(repertoire.positionResponses)), (m) => m.mine).length,
					// );
					const expectedNumMoves = rs.expectedNumMovesFromEpd[repertoire.id][START_EPD];
					// console.debug(
					// 	"Number of moves filter(needed) in repertoire",
					// 	repertoire.name,
					// 	numMoves,
					// 	"Expected moves",
					// 	expectedNumMoves,
					// );
					const completed = isNil(biggestMiss);
					progressState.completed = completed;
					const savedProgress = completed
						? 1
						: Math.min(0.99, getCoverageProgress(numMoves / expectedNumMoves));
					if (numMoves > 0) {
						progressState.percentComplete = Math.max(0.01, savedProgress);
					} else {
						progressState.percentComplete = savedProgress;
					}
					s.repertoireProgressState[repertoire.id] = progressState;
				});
			}),
		checkShowTargetDepthReached: () => {
			set(([s, rs]) => {
				let isFakePastCoverageGoal =
					s.chessboard.getPly() >= 5 &&
					rs.onboarding.isOnboarding &&
					USER_STATE().user?.ratingRange === "0-1000";

				if (
					(s.isPastCoverageGoal || isFakePastCoverageGoal) &&
					s.activeSide !== s.currentSide &&
					s.hasPendingLineToAdd &&
					(!s.showPlansState.hasShown || rs.onboarding.isOnboarding) &&
					UI().mode === "build" &&
					UI().currentView()?.component === RepertoireBuilder
				) {
					trackEvent(`${UI().mode}.coverage_reached`, {
						hasPlans: !isNil(
							rs.positionReports[s.activeRepertoireId!]?.[s.chessboard.getCurrentEpd()]?.plans,
						),
					});
					UI().pushView(TargetCoverageReachedView, { props: { auto: true } });
					s.showPlansState.hasShown = true;
					s.showPlansState.coverageReached = true;
					s.showPlansState.autoShown = true;
				}
				if (!s.isPastCoverageGoal) {
					s.showPlansState.hasShown = false;
				}
			});
		},
		updateTableResponses: () =>
			set(([s, rs, gs]) => {
				if (!s.activeRepertoireId || !rs.repertoires) {
					s.tableResponses = [];
					return;
				}
				const threshold = gs.userState.getCurrentThreshold();
				const mode = UI().mode;
				const currentSide: Side = s.chessboard.getTurn();
				const currentEpd = s.chessboard.getCurrentEpd();
				const positionReport =
					rs.positionReports[s.activeRepertoireId]?.[s.chessboard.getCurrentEpd()];
				const _tableResponses: Record<string, TableResponse> = {};
				positionReport?.suggestedMoves
					.filter((sm) => GameResultsDistribution.getTotalGames(sm.results)! > 0)
					.map((sm) => {
						_tableResponses[sm.sanPlus] = {
							suggestedMove: sm,
							tags: [],
							side: s.activeSide as Side,
						};
					});
				const isMySide = s.activeSide === s.currentSide;
				const totalPeerGames = sum(
					map(_tableResponses, (r) => {
						const results = r.suggestedMove?.results;
						if (!results) {
							return 0;
						}
						return GameResultsDistribution.getTotalGames(results);
					}),
				);
				const totalMasterGames = sum(
					map(_tableResponses, (r) => {
						const masterResults = r.suggestedMove?.masterResults;
						if (!masterResults) {
							return 0;
						}
						return GameResultsDistribution.getTotalGames(masterResults);
					}),
				);
				if (
					positionReport &&
					((isMySide &&
						Object.keys(_tableResponses).length < 4 &&
						totalMasterGames < 30 &&
						totalPeerGames < 50) ||
						(!isMySide && Object.keys(_tableResponses).length === 0))
				) {
					if (Stockfish.epd !== currentEpd) {
						Stockfish.getTopMoves(s.chessboard.getCurrentEpd(), 3, (_stockfishMoves) => {
							set(([s]) => {
								s.updateTableResponses();
							});
						});
					}
				} else {
					// in case we started before getting a good position report, don't waste cycles
					Stockfish.cancel();
				}
				const existingMoves =
					rs.repertoires[s.activeRepertoireId]?.positionResponses[s.chessboard.getCurrentEpd()];
				existingMoves?.map((r) => {
					if (_tableResponses[r.sanPlus]) {
						_tableResponses[r.sanPlus].repertoireMove = r;
					} else {
						_tableResponses[r.sanPlus] = {
							repertoireMove: r,
							tags: [],
							side: s.activeSide as Side,
						};
					}
				});
				Stockfish?.pendingMoves?.map((stockfishMove) => {
					const san = stockfishMove.san;
					if (_tableResponses[san]) {
						_tableResponses[san].stockfishMove = stockfishMove;
					} else {
						_tableResponses[san] = {
							stockfishMove: stockfishMove,
							tags: [],
							side: s.activeSide as Side,
						};
					}
				});
				const ownSide = currentSide === s.activeSide;
				const usePeerRates = shouldUsePeerRates(positionReport);
				let tableResponses = values(_tableResponses);
				if (mode === "browse") {
					tableResponses = tableResponses.filter((tr) => tr.repertoireMove);
				}
				const biggestMisses = rs.repertoireGrades[s.activeRepertoireId]?.biggestMisses ?? {};
				tableResponses.forEach((tr) => {
					const epd = tr.suggestedMove?.epdAfter || tr.repertoireMove?.epdAfter!;
					if (biggestMisses[epd]) {
						tr.biggestMiss = biggestMisses[epd];
					}
				});
				tableResponses.forEach((tr) => {
					if (ownSide && tr.suggestedMove && positionReport) {
						const positionWinRate = GameResultsDistribution.getWinRate(
							positionReport?.results,
							s.activeSide as Side,
						);
						const [, , ci] = GameResultsDistribution.getWinRateRange(
							tr.suggestedMove.results,
							s.activeSide as Side,
						);
						const moveWinRate = GameResultsDistribution.getWinRate(
							tr.suggestedMove.results,
							s.activeSide as Side,
						);
						if (ci > 0.12 && Math.abs(positionWinRate - moveWinRate) > 0.02) {
							tr.lowConfidence = true;
						}
					}
				});
				tableResponses.forEach((tr) => {
					if (!ownSide && mode === "build") {
						if (
							// @ts-ignore
							tr.suggestedMove?.incidence < threshold &&
							tr.suggestedMove?.needed
						) {
							tr.tags.push(MoveTag.RareDangerous);
						}
					}
				});
				const now = new Date().toISOString();
				tableResponses.forEach((tr) => {
					if (UI().mode === "browse" && tr.repertoireMove) {
						const epd = tr.suggestedMove?.epdAfter || tr.repertoireMove.epdAfter;
						let dueBelow = rs.numMovesDueFromEpd[s.activeRepertoireId!]?.[epd];
						let earliestBelow = rs.earliestReviewDueFromEpd[s.activeRepertoireId!]?.[epd];
						const dueAt = tr.repertoireMove.srs?.dueAt;
						if (dueAt && (dueAt < earliestBelow || !earliestBelow)) {
							earliestBelow = dueAt;
						}
						const isDue =
							tr.repertoireMove.srs &&
							SpacedRepetitionStatus.isReviewDue(tr.repertoireMove.srs, now);
						dueBelow = dueBelow + (isDue ? 1 : 0);
						tr.reviewStatus = {
							earliestDue: earliestBelow,
							due: dueBelow,
						};
					}
				});
				const stockfishEvals: StockfishEval[] = filter(
					map(tableResponses, (tr) => {
						return tr.suggestedMove?.stockfish;
					}),
					(stockfish) => !isNil(stockfish),
				) as StockfishEval[];
				const bestStockfishEval = maxBy(stockfishEvals, (stockfish: StockfishEval) => {
					return stockfish.getPovWinPercentage(s.currentSide!);
				});
				tableResponses.forEach((tr) => {
					if (!ownSide && mode === "build") {
						if (
							isCommonMistake(tr, positionReport, bestStockfishEval) &&
							!tr.tags.includes(MoveTag.RareDangerous)
						) {
							tr.tags.push(MoveTag.CommonMistake);
						}
					}
				});
				tableResponses.forEach((tr) => {
					if (tr.repertoireMove?.isDisabled) {
						tr.tags.push(MoveTag.Disabled);
					}
				});
				tableResponses.forEach((tr) => {
					if (mode !== "build") {
						return;
					}
					if (tr.repertoireMove || !tr.suggestedMove) {
						return;
					}
					const epdAfter = tr.suggestedMove.epdAfter;

					if (!tr.repertoireMove && rs.epdNodes[s.activeRepertoireId!]?.[epdAfter]) {
						tr.transposes = true;
						tr.tags.push(MoveTag.Transposes);
					}

					if (isTheoryHeavy(tr, currentEpd) && ownSide) {
						tr.tags.push(MoveTag.TheoryHeavy);
					}
				});
				tableResponses.forEach((tr) => {
					const moveRating = getMoveRating({
						positionReport,
						after: tr.suggestedMove?.stockfish,
						before: bestStockfishEval,
						// @ts-ignore
						suggestedMove: tr.suggestedMove,
						side: currentSide,
					});
					// @ts-ignore
					tr.moveRating = moveRating;
				});
				if (ownSide && tableResponses.length >= 3) {
					tableResponses.forEach((tr, i) => {
						if (mode !== "build") {
							return;
						}
						const allOthersInaccurate = every(tableResponses, (tr, j) => {
							const playedByMasters =
								tr.suggestedMove &&
								SuggestedMove.getPlayRate(tr.suggestedMove, positionReport, true)! > 0.02;
							return (!isNil(tr.moveRating) && !playedByMasters) || j === i;
						});
						const playedEnough =
							GameResultsDistribution.getTotalGames(tr.suggestedMove?.results!)! /
								GameResultsDistribution.getTotalGames(positionReport?.results!)! >
							0.02;
						if (allOthersInaccurate && isNil(tr.moveRating) && playedEnough) {
							tr.tags.push(MoveTag.BestMove);
						}
					});
				}
				tableResponses = scoreTableResponses(
					tableResponses,
					positionReport,
					bestStockfishEval,
					currentSide,
					UI().mode,
					ownSide,
					!usePeerRates,
				);
				if (tableResponses.length >= 3) {
					let contemptValue = 125;
					let minimumContemptAdvantageThreshold = 1.01;

					let highestExpectedScore = maxBy(
						filter(take(tableResponses, 5), (tr) => {
							return (
								!isNil(tr.suggestedMove?.leelaExpectedScore) &&
								tr.moveRating !== MoveRating.Mistake &&
								tr.moveRating !== MoveRating.Blunder
							);
						}),
						(tr) => tr.suggestedMove!.leelaExpectedScore,
					);
					let excludedPositions = new Set();
					excludedPositions.add("rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -");

					let highestContemptTableResponse = maxBy(
						filter(take(tableResponses, 5), (tr) => {
							return (
								!isNil(tr.suggestedMove?.contemptDelta) &&
								tr.moveRating !== MoveRating.Mistake &&
								tr.moveRating !== MoveRating.Blunder
							);
						}),
						(tr) =>
							tr.suggestedMove!.leelaExpectedScore! +
							tr.suggestedMove!.contemptDelta! * contemptValue,
					);
					if (highestContemptTableResponse && mode === "build" && highestExpectedScore) {
						if (
							highestContemptTableResponse.suggestedMove!.leelaExpectedScore! +
								highestContemptTableResponse.suggestedMove!.contemptDelta! * contemptValue >
								(highestExpectedScore.suggestedMove!.leelaExpectedScore! +
									highestExpectedScore.suggestedMove!.contemptDelta! * contemptValue) *
									minimumContemptAdvantageThreshold &&
							highestContemptTableResponse.suggestedMove?.leelaExpectedScore !==
								highestExpectedScore.suggestedMove?.leelaExpectedScore
						) {
							if (!excludedPositions.has(highestContemptTableResponse.suggestedMove!.epdAfter)) {
								highestContemptTableResponse.tags.push(MoveTag.Sharpest);
							}
						}
					}
				}
				s.tableResponses = tableResponses;
				const noneNeeded = every(tableResponses, (tr) => !tr.suggestedMove?.needed);
				if (!isNil(positionReport) && (!ownSide || (ownSide && isEmpty(tableResponses)))) {
					if (noneNeeded || isEmpty(tableResponses)) {
						s.isPastCoverageGoal = true;
					} else {
						s.isPastCoverageGoal = false;
					}
				}
				s.fetchNeededPositionReports();
			}),
		getBiggestMiss: () =>
			get(([s, rs]) => {
				if (!s.activeRepertoireId) {
					return null;
				}
				return rs.repertoireGrades[s.activeRepertoireId!]?.biggestMiss ?? null;
			}),
		getNearestMiss: (): RepertoireMiss | null | undefined =>
			get(([s, rs]) => {
				const repertoire = s.getActiveRepertoire();
				if (!repertoire) {
					return null;
				}
				return rs.getNearestMiss(
					repertoire,
					s.chessboard.get((c) => c.positionHistory),
				);
			}),
		finishSidebarOnboarding: () =>
			set(([s, _rs]) => {
				animateSidebar("right");
				s.reset();
			}),
		fetchNeededPositionReports: () =>
			set(([s, rs]) => {
				const activeRepertoire = s.getActiveRepertoire();
				const debugFriendly = isDevelopment && false;
				let requests: {
					epd: string;
					previousEpds: string[];
					moves: string[];
					repertoireId: Uuid;
				}[] = [];
				forEach(rs.repertoires, (r) => {
					requests.push({
						epd: START_EPD,
						previousEpds: [],
						moves: [],
						repertoireId: r.id,
					});
				});
				if (!isNil(activeRepertoire)) {
					const moveLog: string[] = [];
					const epdLog: string[] = [];
					forEach(
						zip(s.chessboard.get((s) => s).positionHistory, s.chessboard.get((s) => s).moveLog),
						([epd, move], _i) => {
							if (move) {
								moveLog.push(move);
							}
							if (!epd) {
								return;
							}
							epdLog.push(epd);
							requests.push({
								epd: epd!,
								repertoireId: activeRepertoire.id,
								moves: [...moveLog],
								previousEpds: [...epdLog],
							});
						},
					);
					const tableResponses = rs.browsingState.tableResponses;
					if (tableResponses && !debugFriendly) {
						// only first 5 moves since the rest are unlikely
						[
							...take(tableResponses, 5),
							...(filter(tableResponses, (tr) => tr.suggestedMove?.needed) as TableResponse[]),
						].forEach((tr) => {
							const sm = tr.suggestedMove;
							if (!sm) {
								return;
							}
							const epd = sm.epdAfter;
							requests.push({
								epd: epd,
								repertoireId: activeRepertoire.id,
								moves: [...moveLog, sm.sanPlus],
								previousEpds: [...epdLog, epd],
							});
						});
					}
				}
				if (isNil(activeRepertoire)) {
					forEach(rs.repertoires, (rep) => {
						if (rep.side !== "white") {
							return;
						}
						const startResponses = rep?.positionResponses[START_EPD];
						if (startResponses?.length === 1) {
							requests.push({
								epd: startResponses[0].epdAfter,
								previousEpds: [START_EPD],
								moves: [startResponses[0].sanPlus],
								repertoireId: rs.getDefaultRepertoire("white")!.id,
							});
						}
					});
				}
				const createKey = (request: { epd: string; repertoireId: string | null }) => {
					return `${request.epd}-${request.repertoireId}`;
				};
				requests = filter(requests, (r) => !rs.positionReports[r.repertoireId]?.[r.epd]);
				requests = filter(requests, (r) => !s.pendingPositionReports.has(createKey(r)));
				requests = uniqBy(requests, (r) => createKey(r));
				if (isEmpty(requests)) {
					return;
				}
				requests.forEach((request) => {
					s.pendingPositionReports.add(createKey(request));
				});
				rspcClient
					.query(["openings.fetchPositionReports", requests])
					.then((positionReports) => {
						set(([s, rs]) => {
							positionReports.forEach((report) => {
								// let reportMutated = report as Partial<PositionReportDTO> & Partial<PositionReport>;
								s.pendingPositionReports.delete(createKey(report));
								report.suggestedMoves!.forEach((move) => {
									// if (move.annotation) {
									// 	const key = Kep.toKey({ epd: report.epd, san: move.sanPlus });
									// 	rs.moveAnnotations[key] ??= {};
									// 	rs.moveAnnotations[key].null = {
									// 		epd: report.epd,
									// 		san: move.sanPlus,
									// 		text: move.annotation,
									// 		repertoireId: null,
									// 	};
									// }
									if (move.stockfish) {
										// @ts-expect-error
										move.stockfish = new StockfishEval([
											move.stockfish as unknown as StockfishReport,
											sideFromEpd(report.epd),
										]);
									}
								});
								_.set(
									rs.positionReports,
									[report.repertoireId!, report.epd],
									report as unknown as PositionReport,
								);
							});
							s.updateTableResponses();
							s.updatePlans();
							s.fetchNeededPositionReports();
						});
					})
					.finally(() => {
						requests.forEach((request) => {
							s.pendingPositionReports.delete(createKey(request));
						});
					});
			}),
		requestToAddCurrentLine: () =>
			set(([s, rs, gs]) => {
				const subscribed = gs.userState.isSubscribed();

				if (
					!subscribed &&
					rs.pastFreeTier(s.activeSide!) &&
					PAYMENT_ENABLED &&
					!rs.onboarding.isOnboarding
				) {
					UI().replaceView(UpgradeSubscriptionView, {
						props: { pastLimit: true },
					});
					return;
				}
				if (s.hasPendingLineToAdd) {
					s.addPendingLine();
				}
			}),

		updatePlans: () =>
			set(([s, rs]) => {
				const activeRepertoire = s.getActiveRepertoire();
				if (!activeRepertoire) {
					return;
				}
				const plans =
					rs.positionReports[activeRepertoire.id]?.[s.chessboard.getCurrentEpd()]?.plans ?? [];

				const maxOccurence = plans[0]?.occurences ?? 0;
				const consumer = parsePlans(
					cloneDeep(plans),
					activeRepertoire.side,
					s.chessboard.get((s) => s.position),
				);
				s.chessboard.set((c) => {
					c.focusedPlans = [];
					c.plans = consumer.metaPlans.filter((p) => consumer.consumed.has(p.id));
					s.planSections = consumer.planSections;
					c.maxPlanOccurence = maxOccurence;
				});
			}),
		onPositionUpdate: () =>
			set(([s, rs]) => {
				s.currentSide = s.chessboard.getTurn();
				s.pendingResponses = {};

				s.updatePlans();

				const incidences = s.getLineIncidences();
				if (rs.ecoCodeLookup) {
					s.lastEcoCode = rs.getLastEcoCode(s.chessboard.get((s) => s.positionHistory));
				}
				const line = s.chessboard.get((s) => s.moveLog);
				// so we can check if each san is valid from each position
				let isInvalid = false;
				const positionHistory = s.chessboard.get((s) => s.positionHistory);
				map(
					zip(positionHistory, drop(positionHistory, 1), line, incidences),
					([position, nextPosition, san, _incidence], i) => {
						if (!san || !position || isInvalid) {
							return;
						}
						if (isDevelopment) {
							const chessPosition = new Chess(Epd.toFen(position!));
							const validatedMoves = chessPosition.validateMoves([san]);
							if (validatedMoves === null) {
								chessPosition.move(san);
								const nextEpd = genEpd(chessPosition);
								if (nextEpd !== nextPosition) {
									isInvalid = true;
									console.error("This should never happen! EPD mismatch");
									s.chessboard.resetPosition();
									rs.backToOverview();
								}
							}
						}

						const mine = i % 2 === (s.activeSide === "white" ? 0 : 1);
						if (
							!some(s.getActiveRepertoire()?.positionResponses[position!], (m) => {
								return m.sanPlus === san;
							})
						) {
							s.pendingResponses![position!] = {
								epd: position,
								epdAfter: s.chessboard.get((s) => s.positionHistory)[i + 1],
								sanPlus: san,
								side: s.activeSide!,
								mine: mine,
							};
						}
					},
				);

				s.hasAnyPendingResponses = !isEmpty(flatten(values(s.pendingResponses)));
				s.hasPendingLineToAdd = some(flatten(values(s.pendingResponses)), (m) => m.mine);
				Stockfish.cancel();
				s.updateTableResponses();
				s.fetchNeededPositionReports();
				rs.fetchNeededAnnotations();
			}),
		getLineIncidences: () =>
			get(([s, rs]) => {
				if (!s.activeSide) {
					return [];
				}

				let incidence = 1.0;
				return map(
					zip(
						s.chessboard.get((s) => s.positionHistory),
						s.chessboard.get((s) => s.moveLog),
					),
					([position, san], _i) => {
						if (!position || !s.activeRepertoireId) {
							return incidence;
						}
						const positionReport = rs.positionReports[s.activeRepertoireId]?.[position];
						if (positionReport) {
							const suggestedMove = find(positionReport.suggestedMoves, (sm) => sm.sanPlus === san);
							if (suggestedMove) {
								incidence = suggestedMove.incidence ?? 0;
								return incidence;
							}
						}

						return incidence;
					},
				);
			}),
		getIncidenceOfCurrentLine: () =>
			get(([s, _rs]) => {
				return last(s.getLineIncidences());
			}),
		getOpeningNameForMove: (epdAfter: string) => {
			return get(([s, rs]) => {
				if (!s.lastEcoCode) {
					return null;
				}
				const [currentOpeningName, currentVariations] = s.lastEcoCode
					? EcoCode.getAppropriateEcoName(s.lastEcoCode!.fullName)
					: [];
				const nextEcoCode = rs.ecoCodeLookup[epdAfter];
				let includeOpeningName = false;
				if (nextEcoCode) {
					const [name, variations] = EcoCode.getAppropriateEcoName(nextEcoCode.fullName);
					if (name !== currentOpeningName) {
						includeOpeningName = true;
						return name;
					}
					const lastVariation = last(variations);

					if (name === currentOpeningName && lastVariation !== last(currentVariations)) {
						includeOpeningName = true;
						return last(variations);
					}
					if (includeOpeningName) {
						return last(variations);
					}
				}
			});
		},
		addPendingLine: () =>
			set(([s, _rs]) => {
				s.showPlansState.hasShown = false;
				const [loading, setLoading] = createSignal(true);
				const [justCompleted, setJustCompleted] = createSignal(false);
				UI().withoutAnimations(() => {
					UI().cutTo(RepertoireBuilder);
				});
				UI().pushView(RepertoireCheckInView, {
					props: {
						loading: loading,
						justCompleted: justCompleted,
					},
				});
				s.addedLineState.loading = true;
				const completeBefore = s.repertoireProgressState[s.activeRepertoireId!]?.completed;
				return client
					.post("/api/v2/openings/add_moves", {
						moves: flatten(cloneDeep(values(s.pendingResponses))),
						repertoireId: s.activeRepertoireId!,
					})
					.then(({ data }: { data: FetchRepertoireResponse }) => {
						set(([s, rs]) => {
							rs.repertoireFetched(data);
							if (rs.onboarding.isOnboarding) {
								UI().pushView(PracticeIntroOnboarding);
							} else {
								rs.repertoireFetched(data);
								s.onPositionUpdate();
								rs.onRepertoireChange();
								const completeAfter = s.repertoireProgressState[s.activeRepertoireId!]?.completed;
								setLoading(false);
								setJustCompleted(!completeBefore && completeAfter);
							}
						});
					})
					.catch((err) => {
						Sentry.captureException(err);
					})
					.finally(() => {
						set(([s]) => {
							s.addedLineState.loading = false;
						});
					});
			}),
	} as Omit<BrowsingState, "chessboardState">;

	initialState.chessboard = createChessboardInterface()[1];
	initialState.chessboard.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([s]) => {
					s.onPositionUpdate();
				});
			},

			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([s]) => {
					s.showPlansState.hasShown = false;
				});
			},
			onMovePlayed: () => {
				set(([s, rs]) => {
					const repertoireId = s.activeRepertoireId || rs.getDefaultRepertoire("white")?.id;
					if (!repertoireId) {
						return;
					}
					if (includes(["side_overview", "overview"], UI().mode)) {
						UI().pushView(RepertoireBuilder);
						s.startBrowsing(repertoireId, "build", {
							keepPosition: true,
						});
					}

					s.checkShowTargetDepthReached();
				});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					animateSidebar("right");
					if (UI().hasView(RepertoireBuilder)) {
						UI().cutTo(RepertoireBuilder);
					}
					return true;
				}),
		};
	});
	return initialState;
};

function createEmptyRepertoireProgressState(): RepertoireProgressState {
	return {
		pendingMoves: 0,
		completed: false,
		expectedDepth: 0,
		percentComplete: 0,
		showPopover: false,
	};
}
const isCommonMistake = (
	tr: TableResponse,
	positionReport: PositionReport,
	bestStockfishEval: StockfishEval | undefined,
): boolean => {
	if (!tr.suggestedMove || !positionReport) {
		return false;
	}
	if (GameResultsDistribution.getTotalGames(tr.suggestedMove?.results)! < 100) {
		return false;
	}
	if (!tr.suggestedMove?.needed) {
		return false;
	}
	if (
		GameResultsDistribution.getWinRate(tr.suggestedMove.results, Side.flip(tr.side)) >
		GameResultsDistribution.getWinRate(positionReport.results, Side.flip(tr.side)) + 0.02
	) {
		return false;
	}
	const moveRating = getMoveRating({
		positionReport,
		after: tr.suggestedMove?.stockfish,
		before: bestStockfishEval,
		// @ts-ignore
		suggestedMove: tr.suggestedMove,
		side: Side.flip(tr.side),
	});
	if (isNil(moveRating) || moveRating < MoveRating.Mistake) {
		return false;
	}
	return true;
};
