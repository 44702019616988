import { filter, isNil } from "lodash-es";
import { type Accessor, Show, createEffect } from "solid-js";
import { Puff } from "solid-spinner";
import { Course } from "~/types/Course";
import type { CoursePair } from "~/types/CoursePair";
import type { RepertoireMove } from "~/types/RepertoireMove";
import { BROWSING_STATE, UI, quick, useMode } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { c } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { showerSuccessConfetti } from "./Confetti";
import { PreReview } from "./PreReview";
import { RepertoireCompletion } from "./RepertoireCompletion";
import { type SidebarAction, useBiggestGapActions } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";

export const RepertoireCheckInView = (props: {
	loading: Accessor<boolean>;
	addedFromCoursePair?: CoursePair;
	deletedMove?: RepertoireMove;
	justCompleted: Accessor<boolean>;
}) => {
	const currentLine = () => BROWSING_STATE().chessboard.getMoveLog();
	const repertoire = () => BROWSING_STATE().getActiveRepertoire();
	const mode = useMode();
	const reviewCurrentLineAction: SidebarAction = {
		onPress: () => {
			trackEvent(`${mode()}.added_line_state.practice_line`);
			quick((s) => {
				s.repertoireState.reviewState.reviewLine(currentLine(), repertoire()!);
			});
		},
		text: "Practice these moves",
		style: "secondary",
	};
	let actions: Accessor<SidebarAction[]> = () => {
		let actions: SidebarAction[] = [];
		if (props.addedFromCoursePair) {
			let remainingCourseMoves = Course.countMovesFrom(
				props.addedFromCoursePair.details.responses,
				START_EPD,
				repertoire(),
			).totalMoves;
			if (remainingCourseMoves > 0) {
				// actions.push({
				// 	onPress: () => {
				// 		trackEvent(`courses.importRestOfCourse.clicked`);
				// 		quick((s) => {
				// 			s.repertoireState.browsingState.importCourse({
				// 				course: props.addedFromCoursePair!.overview,
				// 				courseDetails: props.addedFromCoursePair!.details,
				// 				fromEpd: START_EPD,
				// 			});
				// 		});
				// 	},
				// 	text: "Add the rest of this course",
				// 	right: `${pluralize(remainingCourseMoves, "move")}`,
				// 	style: "secondary",
				// });
			}
			actions.push({
				onPress: () => {
					trackEvent(`${mode()}.added_line_state.practice_line`);
					quick((_s) => {
						UI().pushView(PreReview, {
							props: {
								repertoireId: repertoire()!.id,
							},
						});
					});
				},
				text: "Practice moves",
				style: "secondary",
			});
			actions = actions.concat(useBiggestGapActions());
		} else {
			actions = actions.concat(useBiggestGapActions());
			actions.push(reviewCurrentLineAction);
		}
		return actions;
	};
	createEffect(() => {});
	createEffect(() => {
		if (!props.loading() && props.justCompleted()) {
			showerSuccessConfetti();
		}
	});
	return (
		<Show
			when={!props.loading()}
			fallback={
				<div class="row w-full justify-center pt-12">
					<Puff color={c.primaries[65]} />
				</div>
			}
		>
			<SidebarTemplate
				header={"Moves saved!"}
				bodyPadding
				actions={filter(actions(), (a) => !isNil(a)) as SidebarAction[]}
			>
				<RepertoireCompletion repertoireId={repertoire()!.id} />
			</SidebarTemplate>
		</Show>
	);
};
