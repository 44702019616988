import { find, isEmpty } from "lodash-es";
import { For, Show, createEffect, createMemo, createSignal } from "solid-js";
import { type OpeningReport, OpeningsReport } from "~/types/OpeningsReport";
import type { Uuid } from "~/types/Uuid";
import type { EnrichedComponent } from "~/types/View";
import { REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import client from "~/utils/client";
import { useLineEcoCode } from "~/utils/eco_codes";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { c } from "~/utils/styles";
import { OpeningReportView } from "./OpeningReportView";
import { SideDot } from "./SideDot";
import { SidebarTable } from "./SidebarTable";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { initTooltip } from "./Tooltip";
import { NewBadge } from "./icons/NewBadge";

export const OpeningsReportView: EnrichedComponent<{
	reports: OpeningsReport[];
}> = (props) => {
	const seenInsights: Set<Uuid> = new Set();
	onBack(() => {
		setInsightsReviewed([...seenInsights], "client");
		quick((_s) => {
			REPERTOIRE_STATE().backToOverview();
		});
	});
	const renderOpening = (opening: OpeningReport) => {
		const eco = useLineEcoCode(opening.line);
		const isNew = !opening.reviewed;
		return (
			<div class="flex items-center row  max-w-full shrink-1">
				<SideDot side={opening.side} size={8} />
				<Spacer width={10} />
				<div class="text-primary font-medium whitespace-nowrap overflow-y-visible overflow-x-clip text-ellipsis text-xs inline-block">
					{eco()?.fullName ?? ""}
				</div>
				<Spacer width={8} />
				<Show when={isNew}>
					<div class="shrink-0 max-w-10 h-2.5 ">
						<NewBadge color={c.colors.purple[50]} />
					</div>
				</Show>
			</div>
		);
	};
	const [reportId, setReportId] = createSignal(
		find(props.reports, (r) => OpeningsReport.countInsights(r) > 0)?.id ?? props.reports[0]?.id,
	);
	const report = createMemo(() => {
		return find(props.reports, (r) => r.id === reportId())!;
	});
	createEffect(() => {
		if (isEmpty(report())) {
			return;
		}
		const insights = OpeningsReport.getAllReports(report())
			.filter((o) => !o.reviewed)
			.map((o) => o.insightId);
		setInsightsReviewed(insights, "server");
		for (const insight of insights) {
			seenInsights.add(insight);
		}
	});
	const setInsightsReviewed = (insightIds: Uuid[], on: "server" | "client" | "both" = "both") => {
		if (on === "client" || on === "both") {
			const insightsSet = new Set(insightIds);
			quick((s) => {
				s.repertoireState.openingReportsState
					.openingsReports!.flatMap((o) => OpeningsReport.getAllReports(o))
					.forEach((o) => {
						if (!o) {
							return;
						}
						if (insightsSet.has(o.insightId)) {
							o.reviewed = true;
						}
					});
			});
		}
		if (on === "server" || on === "both") {
			client.post("/api/v1/openings_report/insight_reviewed", {
				insightIds,
			});
		}
	};
	const onClickReport = (opening: OpeningReport, type: "best" | "worst") => {
		setInsightsReviewed([opening.insightId], "both");
		quick((_s) => {
			UI().pushView(OpeningReportView, {
				props: {
					type: type,
					report: opening,
					eco: useLineEcoCode(opening.line)()!,
					overallReport: report(),
				},
			});
		});
	};
	const renderBottomBorder = (active: boolean) => {
		return (
			<div
				class={clsx(
					"absolute bottom-0 left-0 right-0 h-0.5 transition-colors bg-primary",
					active ? "bg-gray-80" : "bg-gray-20",
				)}
			></div>
		);
	};
	return (
		<SidebarTemplate header={"Your openings report"} actions={[]}>
			<Show when={props.reports.length > 1}>
				<div class="flex gap-2 row relative">
					{renderBottomBorder(false)}
					<For each={props.reports}>
						{(r) => (
							<div
								use:onClick={() => setReportId(r.id)}
								class={clsx(
									"text-sm  transition-colors cursor-pointer py-4 font-medium relative padding-sidebar",
									reportId() === r.id ? "text-primary" : "text-tertiary &hover:text-primary",
								)}
							>
								{r.timeClass}, {r.mode}
								{renderBottomBorder(reportId() === r.id)}
								{/*
								<div
									class={clsx(
										"absolute top-0 right-0 h-[10px] w-[10px] rounded-full translate-x-1/2 -translate-y-1/2  transition-opacity bg-purple-50",
										OpeningsReport.countInsights(r) > 0
											? "opacity-100"
											: "opacity-0",
									)}
								></div>
                */}
							</div>
						)}
					</For>
				</div>
			</Show>
			<Show when={isEmpty(props.reports)}>
				<Spacer height={12} />
				<p class="padding-sidebar body-text">
					We weren't able to find any openings where you are over or under-performing in the past 90
					days on your connected account(s). You may need to play more games for us to get a bigger
					sample size.
				</p>
			</Show>
			<Show when={!isEmpty(props.reports)}>
				<Spacer between={["tabs", "table"]} />
				<Show when={report().bestOpenings.length > 0}>
					<SidebarTable
						title="Best openings"
						leftColumns={[
							{
								label: "Opening",
								labelStyle: "hidden",
								width: "auto",
								render: (opening) => {
									return renderOpening(opening);
								},
							},
						]}
						rows={report().bestOpenings}
						onClick={(r) => onClickReport(r, "best")}
						rightColumns={[
							{
								label: "Performance",
								width: 70,
								align: "right",
								render: (opening) => {
									const diff = Math.round(opening.minStrength - report().minStrength);
									return (
										<div
											class="text-green-70"
											ref={(ref) => {
												initTooltip({
													ref,
													content: () => {
														return `You perform ${diff} points above your average, when you play this opening.`;
													},
													maxWidth: 160,
												});
											}}
										>
											+{diff} Elo
										</div>
									);
								},
							},
						]}
					/>
				</Show>
				<Show when={report().worstOpenings.length > 0}>
					<Show when={report().bestOpenings.length > 0}>
						<Spacer between={["table", "table"]} />
					</Show>
					<SidebarTable
						title="Worst openings"
						leftColumns={[
							{
								label: "Opening",
								labelStyle: "hidden",
								width: "auto",
								render: (opening) => {
									return renderOpening(opening);
								},
							},
						]}
						rows={report().worstOpenings}
						onClick={(r) => onClickReport(r, "worst")}
						rightColumns={[
							{
								label: "Performance",
								width: 70,
								align: "right",
								render: (opening) => {
									const diff = Math.round(opening.maxStrength - report().maxStrength);
									return (
										<div
											class="text-red-70"
											ref={(ref) => {
												initTooltip({
													ref,
													content: () => {
														return `You perform ${Math.abs(
															diff,
														)} points below your average, when you play this opening.`;
													},
													maxWidth: 160,
												});
											}}
										>
											{diff} Elo
										</div>
									);
								},
							},
						]}
					/>
				</Show>
				<Spacer between={["table", "body-text"]} />
				<p class="padding-sidebar body-text">
					This report was generated from the last <b>{report().gamesCount}</b> games on your
					connected account(s), in the last <b>90</b> days.
				</p>
			</Show>
		</SidebarTemplate>
	);
};

registerViewMode(OpeningsReportView, "openings_report");
