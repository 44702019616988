import { destructure } from "@solid-primitives/destructure";
import { isNil } from "lodash-es";
import { type Accessor, type JSXElement, Show } from "solid-js";
import { CMText } from "~/components/CMText";
import { GameResultsBar } from "~/components/GameResultsBar";
import { MasteryProgressBar } from "~/components/MasteryProgressBar";
import { ReviewText } from "~/components/ReviewText";
import type { SidebarTableColumn } from "~/components/SidebarTable";
import { Spacer } from "~/components/Space";
import { StockfishEvalPreview } from "~/components/StockfishEvalPreview";
import { TableCoverageProgressBar } from "~/components/TableCoverageProgressBar";
import { initTooltip } from "~/components/Tooltip";
import type { CourseDetailsDTO, CourseOverviewDTO } from "~/rspc";
import { GameResultsDistribution } from "~/types/GameResults";
import type { PositionReport } from "~/types/PositionReport";
import type { Repertoire } from "~/types/Repertoire";
import { Side } from "~/types/Side";
import { StockfishEval } from "~/types/StockfishEval";
import type { SuggestedMove } from "~/types/SuggestedMove";
import { TableResponse } from "~/types/TableResponse";
import { BROWSING_STATE, USER_STATE, useMode } from "~/utils/app_state";
import { formatPlayPercentage, isNegligiblePlayrate } from "~/utils/results_distribution";
import { c, stylex } from "~/utils/styles";
import { clsx } from "./classes";
import { isMobile } from "./isMobile";
import { getMoveRatingIcon } from "./move_inaccuracy";

export type TableResponseRow = {
	tableResponse: Accessor<TableResponse>;
	suggestedMove: Accessor<SuggestedMove | undefined>;
	// courseMove: Accessor<CourseMoveDTO | undefined>;
	positionReport: Accessor<PositionReport | undefined>;
	courseStats: Accessor<CourseDetailsDTO["epdStats"] | undefined>;
	earliestDueDate: Accessor<string | undefined>;
	numMovesDueFromHere: Accessor<number>;
	side: Accessor<Side>;
	line: Accessor<string[]>;
	epd: Accessor<string>;
	annotation: Accessor<string | undefined>;
	openingName: Accessor<string | null | undefined>;
	tagsRow: Accessor<JSXElement | undefined>;
	unclickable: Accessor<boolean>;
	isFaded: Accessor<boolean>;
};

export namespace TableResponseRow {
	export const getStockfishEval = (tr: TableResponseRow): StockfishEval | undefined => {
		let courseMove = tr.tableResponse().courseMove;
		if (courseMove) {
			let stats = tr.courseStats();
			if (!stats) {
				return undefined;
			}
			let stockfishEval = stats[courseMove.epdAfter]?.stockfishEval;
			if (isNil(stockfishEval)) {
				return undefined;
			}
			return new StockfishEval(stockfishEval);
		}
		return tr.suggestedMove()?.stockfish ?? tr.tableResponse()?.stockfishMove?.stockfishEval;
	};
	export const getResultsDistribution = (
		trr: TableResponseRow,
	): GameResultsDistribution | undefined => {
		let courseMove = trr.tableResponse().courseMove;
		if (courseMove) {
			let stats = trr.courseStats();
			if (!stats) {
				return undefined;
			}
			return stats[courseMove.epdAfter]?.results;
		}
		return trr.suggestedMove()?.results;
	};
	export const getGamesBefore = (trr: TableResponseRow): number | null => {
		let courseMove = trr.tableResponse().courseMove;
		if (courseMove) {
			let stats = trr.courseStats();
			if (!stats) {
				return 0;
			}
			return GameResultsDistribution.getTotalGames(stats[courseMove.epd]?.results) ?? null;
		}
		let positionReportResults = trr.positionReport()?.results;
		if (!positionReportResults) {
			return null;
		}
		return GameResultsDistribution.getTotalGames(positionReportResults);
	};
	export const getIncidence = (trr: TableResponseRow): number | null => {
		let courseMove = trr.tableResponse().courseMove;
		if (courseMove) {
			let stats = trr.courseStats();
			if (!stats) {
				return null;
			}
			return stats[courseMove.epdAfter]?.incidence ?? 0;
		}
		return (
			trr.tableResponse().suggestedMove?.incidence ??
			trr.tableResponse().repertoireMove?.incidence ??
			null
		);
	};
	export const getPlayRate = (
		trr: TableResponseRow,
		opts: { usePeerRates: boolean },
	): number | null | undefined => {
		let courseMove = trr.tableResponse().courseMove;
		let stats = trr.courseStats();
		if (courseMove && stats) {
			let key: "results" | "masterResults" = opts.usePeerRates ? "masterResults" : "results";
			let playRate = GameResultsDistribution.getPlayRate(
				stats[courseMove.epd][key]!,
				stats[courseMove.epdAfter][key]!,
			);
			return playRate;
		}
		return (
			trr.positionReport() &&
			TableResponse.getPlayRate(trr.tableResponse(), trr.positionReport()!, {
				usePeerRates: opts.usePeerRates,
			})
		);
	};
}

export const getMovesTableColumnsRight = (props: {
	myTurn: boolean;
	course?: CourseOverviewDTO;
	usePeerRates?: boolean;
	allLocalStockfish: boolean;
	repertoire?: Repertoire;
}): SidebarTableColumn<TableResponseRow>[] => {
	const { usePeerRates } = destructure(props);
	let columns: SidebarTableColumn<TableResponseRow>[] = [];
	const activeSide = () => BROWSING_STATE().activeSide;
	const threshold = () => {
		const userThreshold = USER_STATE().getCurrentThreshold();
		if (props.repertoire?.coverageTarget) {
			return props.repertoire.coverageTarget;
		}
		return userThreshold;
	};
	const textStyles = stylex(
		c.fg(c.gray[80]),
		c.weightSemiBold,
		c.fontSize(12),
		c.lineHeight("1.3rem"),
	);
	const naStyles = stylex(textStyles, c.fg(c.gray[50]));
	const zero = () => <p style={stylex(naStyles)}>0%</p>;
	const na = () => <p style={stylex(naStyles)}>N/A</p>;
	const _side: Accessor<Side> = () => activeSide() as Side;

	const mode = useMode();
	if (mode() === "browse") {
		columns = [getMasteryColumn(), getDueColumn()];
	} else if (mode() === "review") {
		columns = [];
	} else {
		if (!props.myTurn && !props.allLocalStockfish) {
			columns.push({
				width: 100,
				align: "left",
				render: (props) => {
					const incidence = TableResponseRow.getIncidence(props);
					const denominator = Math.round(1 / (incidence ?? 0.0001));
					const belowCoverageGoal = (incidence ?? 0) < threshold();
					let veryRare = false;
					let hideGamesText = false;
					if (denominator >= 1000) {
						hideGamesText = true;
					}
					if (denominator >= 10000) {
						veryRare = true;
					}
					const sanPlus = TableResponse.getSan(props.tableResponse());
					return (
						<>
							{
								<div
									style={stylex(c.column)}
									ref={(ref) => {
										initTooltip({
											ref,
											content: () => (
												<p>
													{veryRare ? (
														<>You should expect to see this move in less than 1 in 10,000 games.</>
													) : (
														<>
															You'll see the position after <b>{sanPlus}</b> in{" "}
															<b>1 in {denominator.toLocaleString()}</b> games as{" "}
															{Side.flip(props.side())}
														</>
													)}
												</p>
											),
											maxWidth: 200,
										});
									}}
								>
									<CMText style={stylex(textStyles, belowCoverageGoal && stylex(c.fg(c.gray[44])))}>
										{veryRare ? (
											<>Very rare</>
										) : (
											<>
												<b>1</b> in <b>{denominator.toLocaleString()}</b>{" "}
												{hideGamesText ? "" : "games"}
											</>
										)}
									</CMText>
								</div>
							}
						</>
					);
				},
				label: "Expected in",
			});
		}
		if (props.myTurn && !props.allLocalStockfish) {
			columns.push({
				width: 44,
				align: "center",
				render: (props) => {
					const playRate = TableResponseRow.getPlayRate(props, {
						usePeerRates: usePeerRates?.() ?? false,
					});

					return (
						<div
							ref={(ref) => {
								if (playRate) {
									initTooltip({
										ref,
										content: () => (
											<p>
												<b>{formatPlayPercentage(playRate!)}</b> of{" "}
												{usePeerRates?.() ? "players in your rating range" : "masters"} choose this
												move
											</p>
										),
										maxWidth: 200,
									});
								}
							}}
						>
							<Show when={playRate} fallback={na()}>
								<Show when={!isNegligiblePlayrate(playRate!)} fallback={zero()}>
									<p style={stylex(textStyles)}>{formatPlayPercentage(playRate!)}</p>
								</Show>
							</Show>
						</div>
					);
				},
				label: usePeerRates?.() ? "Peers" : "Masters",
			});
		}
		if (props.myTurn || props.allLocalStockfish) {
			columns.push({
				width: 40,
				align: "center",
				render: (props) => {
					const stockfishEval = () => TableResponseRow.getStockfishEval(props);
					return (
						<>
							<Show when={stockfishEval()}>
								<>
									<StockfishEvalPreview
										stockfish={stockfishEval()!}
										thinking={stockfishEval()!.thinking}
										faded={TableResponse.getStockfishEvalSource(props.tableResponse()) === "local"}
									/>
								</>
							</Show>
						</>
					);
				},
				label: "Eval",
			});
		}
		if (!props.myTurn && !props.course) {
			columns.push({
				width: 84,
				align: "center",
				render: (props) => {
					return <>{<TableCoverageProgressBar tableResponse={props.tableResponse()} />}</>;
				},
				label: "Your coverage",
			});
		}
		if (props.myTurn && !props.allLocalStockfish) {
			columns.push({
				width: isMobile() ? 80 : 80,
				align: "center",
				render: (props) => {
					if (!props.suggestedMove() && !props.tableResponse().courseMove) {
						return na();
					}
					if (!props.suggestedMove()?.results && !props.tableResponse().courseMove) {
						return zero();
					}
					let gameResults = TableResponseRow.getResultsDistribution(props);
					return (
						<>
							<Show when={gameResults}>
								<div
									style={stylex(c.fullWidth)}
									ref={(ref) => {
										initTooltip({
											ref,
											content: () => {
												return (
													<div>
														<p class="text-left">
															<span class="block pb-2">When this is played at your level:</span>
															•
															<span class="pr-2" />
															White wins{" "}
															<b>
																{formatPlayPercentage(
																	GameResultsDistribution.getWinRate(gameResults!, "white"),
																)}
															</b>{" "}
															of games <br />
															•
															<span class="pr-2" />
															Black wins{" "}
															<b>
																{formatPlayPercentage(
																	GameResultsDistribution.getWinRate(gameResults!, "black"),
																)}
															</b>{" "}
															of games <br />
															•
															<span class="pr-2" />
															<b>
																{formatPlayPercentage(
																	1 -
																		GameResultsDistribution.getWinRate(gameResults!, "white") -
																		GameResultsDistribution.getWinRate(gameResults!, "black"),
																)}
															</b>{" "}
															of games are drawn
														</p>
														{
															<p class="pt-2 text-left">
																{props.tableResponse().lowConfidence && (
																	<i class="fa fa-warning pr-1 text-yellow-50" />
																)}
																{props.tableResponse().lowConfidence && "Small sample size ("}
																{GameResultsDistribution.getTotalGames(
																	gameResults!,
																)?.toLocaleString()}{" "}
																games
																{props.tableResponse().lowConfidence && ")"}
															</p>
														}
													</div>
												);
											},
											maxWidth: 240,
										});
									}}
								>
									<GameResultsBar
										lowConfidence={!!props.tableResponse().lowConfidence}
										activeSide={activeSide()!}
										gameResults={gameResults!}
									/>
								</div>
							</Show>
						</>
					);
				},
				label: isMobile() ? "Peer results" : "Peer results",
			});
		}
	}
	return columns;
};

export const getMovesTableColumnsLeft = (opts?: {
	course?: CourseOverviewDTO;
	myTurn?: boolean;
}): SidebarTableColumn<TableResponseRow>[] => {
	let columns: SidebarTableColumn<TableResponseRow>[] = [];
	const currentSide = () => BROWSING_STATE().currentSide;
	columns.push({
		label: "San",
		labelStyle: "hidden",
		width: ["dynamic", 40],
		align: "left",
		alignVertical: "top",

		render: (props, { callbacks }) => {
			const moveNumber = () => Math.floor(props.line().length / 2) + 1;
			return (
				<>
					<div style={stylex(c.row, c.alignCenter)}>
						<div style={stylex()} class="text-sm">
							<div
								style={stylex(c.row, c.alignCenter)}
								ref={(e) => {
									callbacks.onRender(e);
								}}
							>
								<CMText class={"text-gray-60 font-semibold leading-5 tracking-wider"}>
									{moveNumber}
									{currentSide() === "black" ? "…" : "."}
								</CMText>
								<Spacer width={4} />
								<p class={"text-gray-85 font-bold leading-5 tracking-wider"}>
									{props.tableResponse().reviewInfo?.hideSan
										? "＿"
										: TableResponse.getSan(props.tableResponse())}
								</p>
								{!isNil(props.tableResponse().moveRating) && (
									<>
										<Spacer width={4} />

										{() => getMoveRatingIcon(props.tableResponse().moveRating!)}
									</>
								)}
							</div>
						</div>
					</div>
				</>
			);
		},
	});
	let courseAndMyTurn = opts?.course && !opts?.myTurn;
	columns.push({
		label: courseAndMyTurn ? "This repertoire covers these moves" : "Annotation / opening / etc",
		labelStyle: courseAndMyTurn ? "hidden" : "hidden",
		width: "auto",
		align: "left",
		render: (props) => {
			const hasInlineAnnotationOrOpeningName = () =>
				props.openingName() || (!isMobile() && props.annotation());
			return (
				<>
					<div class={clsx(" grow column")} style={stylex()}>
						<CMText style={stylex(c.fg(c.gray[80]), c.fontSize(12), c.lineHeight("1.3rem"))}>
							<Show when={props.openingName()}>
								<b>{props.openingName()}</b>
								<Show when={!isMobile() && props.annotation()}>
									<>
										. <Spacer width={2} />
									</>
								</Show>
							</Show>
							<Show when={!isMobile()}>
								<p>{props.annotation()}</p>
							</Show>
						</CMText>
						{props.tagsRow() && (
							<>
								{hasInlineAnnotationOrOpeningName() && <Spacer height={12} />}
								{props.tagsRow()}
							</>
						)}
					</div>
				</>
			);
		},
	});
	return columns;
};

const getDueColumn = (): SidebarTableColumn<TableResponseRow> => {
	return {
		width: ["dynamic", 80],
		align: "right",
		render: (props, { callbacks }) => {
			const disabled = props.tableResponse()?.repertoireMove?.isDisabled;
			if (disabled) {
				return null;
			}
			return (
				<div
					ref={(e) => {
						callbacks.onRender(e);
					}}
				>
					<ReviewText
						date={props.tableResponse().reviewStatus!.earliestDue}
						numDue={props.tableResponse().reviewStatus!.due}
					/>
				</div>
			);
		},
		label: "Status",
	};
};

const getMasteryColumn = (): SidebarTableColumn<TableResponseRow> => {
	return {
		width: 74,
		align: "right",
		render: (props) => {
			const disabled = props.tableResponse()?.repertoireMove?.isDisabled;
			if (disabled) {
				return null;
			}
			return <MasteryProgressBar tableResponse={props.tableResponse()} />;
		},
		label: "Mastery",
	};
};
