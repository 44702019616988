import type { JSXElement } from "solid-js";
import { CMText } from "./CMText";

export const SidebarHeader = (props: {
	children: JSXElement;
	right?: JSXElement;
}) => {
	return (
		<div class="row justify-between items-center mt-0 lg:mt-3">
			<CMText class="text-primary  text-xl font-semibold  lg:text-2xl leading-relaxed align-bottom ">
				{props.children}
			</CMText>
			{props.right}
		</div>
	);
};
