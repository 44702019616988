import { Dialog } from "@capacitor/dialog";
import { UI, quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { SidebarTemplate } from "./SidebarTemplate";

export const DeleteAccountView = () => {
	return (
		<SidebarTemplate
			actions={[
				{
					onPress: () => {
						quick((s) => {
							s.userState.deleteAccount().then(() => {
								Dialog.alert({
									title: "Account deleted!",
									message: "Your account has been deleted and you have been logged out.",
								}).then(() => {
									quick((_s) => {
										UI().backOne();
									});
								});
							});
						});
					},
					text: "Delete my account",
					style: "primary",
				},
			]}
			header={"Delete your account?"}
			bodyPadding={true}
			loading={false}
		>
			<p class={clsx("text-secondary leading-5 pb-2")}>
				You will lose access to your repertoire, and will not be able to recover your account.
			</p>
		</SidebarTemplate>
	);
};
