import { isEmpty } from "lodash-es";
import { APP_STATE } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { isDevelopment } from "~/utils/env";
import { pluralize } from "~/utils/pluralize";

export const MistakesStatus = (props: {
	class?: string;
	ref?: HTMLDivElement | undefined;
}) => {
	const lichessMistakes = () => {
		if (isDevelopment) {
			// return [];
		}
		return APP_STATE().repertoireState.lichessMistakes;
	};
	return (
		<div ref={props.ref} class={clsx("flex row text-xs font-semibold")}>
			<p class={clsx("truncate", isEmpty(lichessMistakes()) ? "text-tertiary" : "text-secondary")}>
				{lichessMistakes()?.length
					? `${pluralize(lichessMistakes()!.length, "Mistake")}`
					: "No mistakes"}
			</p>
			<i
				class={clsx(
					"fa ml-2",
					isEmpty(lichessMistakes()) ? "text-tertiary" : "text-orange-60 ",
					isEmpty(lichessMistakes()) ? "fa-circle-check" : "fa-circle-x",
				)}
			/>
		</div>
	);
};
