import { createSignal } from "solid-js";
import { isServer } from "solid-js/web";
import { isDevelopment } from "./env";
import posthog from "posthog-js";

export const [posthogFeaturesLoaded, setPosthogFeaturesLoaded] = createSignal(false);

type PosthogFeature =
	| "sticky-homepage-cta"
	| "homepage-header-cta"
	| "homepage-cta"
	| "homepage-cta-v2"
	| "homepage-image"
	| "free-tier-limit"
	| "default-chessboard-theme"
	| "limit-onboarding"
	| "pricing";

const overrides: Partial<Record<PosthogFeature, string>> = {};
// overrides
if (isDevelopment && !isServer) {
	// overrides["free-tier-limit"] = "200-my-moves";
	overrides.pricing = "7";
	overrides["homepage-cta-v2"] = "control";
	overrides["limit-onboarding"] = "three-moves";
	// overrides["default-chessboard-theme"] = "control";
	// overrides["homepage-image"] = "2";
	// overrides["homepage-header-cta"] = "2";
}

export const getFeature = (feature: PosthogFeature): boolean | string => {
	if (!posthogFeaturesLoaded()) {
		return false;
	}

	const val = overrides[feature] || posthog.getFeatureFlag(feature);
	if (val !== "control" && val) {
		return val;
	}
	return false;
};
