import { useLocation } from "@solidjs/router";
import { type Component, Match, Switch, createEffect, onMount } from "solid-js";
import LandingPageWrapper from "~/components/LandingPageWrapper";
import { APP_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { isNative } from "~/utils/env";
import { trackEvent } from "~/utils/trackEvent";
import { identifyOnce } from "~/utils/user_properties";
import { AuthStatus } from "~/utils/user_state";
import { ChessbookRoot } from "./ChessbookRoot";
import { OnboardingIntro } from "./SidebarOnboarding";

export const PageWrapper = (props: { initialView?: Component }) => {
	const token = () => APP_STATE().userState.token;
	const repertoireLoading = () => REPERTOIRE_STATE().repertoires === undefined;
	const authStatus = () => USER_STATE().authStatus;
	const location = useLocation();

	onMount(() => {
		identifyOnce({ initial_page: location.pathname });
	});

	createEffect(() => {
		if (authStatus() === AuthStatus.Authenticated && repertoireLoading()) {
			quick((s) => {
				s.repertoireState.initState();
			});
		}
	});
	onMount(() => {
		quick((s) => {
			if (props.initialView) {
				s.userState.pastLandingPage = true;
				s.repertoireState.onboarding.isOnboarding = false;
				UI().pushView(props.initialView);
			}
		});
	});
	const showLandingPage = () => {
		return !(token().value || USER_STATE().pastLandingPage || repertoireLoading());
	};
	createEffect(() => {
		if (showLandingPage() && isNative) {
			quick((s) => {
				trackEvent("onboarding.started");
				s.repertoireState.onboarding.isOnboarding = true;
				UI().pushView(OnboardingIntro);
				s.userState.pastLandingPage = true;
			});
		}
	});

	// return <SidebarLayout mode={mode()} />;
	return (
		<Switch fallback={<LandingPageWrapper />}>
			<Match when={!showLandingPage() || isNative}>
				<ChessbookRoot />
			</Match>
		</Switch>
	);
};
