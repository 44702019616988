import { filter } from "lodash-es";
import { type JSXElement, Match, Show, Switch, createMemo } from "solid-js";
import { Spacer } from "~/components/Space";
import { Line } from "~/types/Line";
import type { RepertoireMiss } from "~/types/RepertoireGrade";
import { BROWSING_STATE, UI, quick, useMode } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { useLineEcoCode } from "~/utils/eco_codes";
import { isMobile } from "~/utils/isMobile";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { CMText } from "./CMText";
import { Intersperse } from "./Intersperse";
import { RepertoireBuilder } from "./RepertoireBuilder";

export interface SidebarAction {
	rightText?: string;
	onPress?: () => void;
	static?: boolean;
	class?: string;
	text: string | JSXElement;
	submitsForm?: string;
	right?: JSXElement | string;
	subtext?: string;
	style: "primary" | "focus" | "secondary" | "tertiary" | "wide";
	shrink?: "left" | "right" | undefined;
	disabled?: boolean;
	off?: boolean;
	hidden?: boolean;
}

export const SidebarFullWidthButton = (props: {
	action: SidebarAction;
	first?: boolean;
}) => {
	const responsive = useResponsiveV2();
	const styles = createMemo(() => {
		let subtextColor = null as string | null;
		let textStyles = stylex();
		let height: number | undefined = undefined;
		if (props.action.style === "focus") {
			subtextColor = c.gray[20];
		}
		if (props.action.style === "wide") {
			textStyles = stylex(textStyles, c.fontSize(16), c.weightBold);
			// subtextColor = c.gray[20];
			height = 60;
		}
		if (props.action.style === "tertiary") {
			subtextColor = c.gray[20];
		}
		if (props.action.style === "secondary") {
			subtextColor = c.gray[60];
		}
		if (props.action.style === "primary") {
			subtextColor = c.gray[70];
		}
		return {
			subtextColor,
			textStyles,
			height,
		};
	});
	const shrinkSide = () => props.action.shrink ?? "right";
	return (
		<div
			use:onClick={(e) => {
				e.preventDefault();
				if (!props.action.disabled) {
					props.action.onPress?.();
				}
			}}
			{...(props.action.submitsForm
				? {
						type: "submit",
						form: props.action.submitsForm,
					}
				: {})}
			class={clsx(
				"cursor-pointer transition-all select-none",
				props.action.style !== "wide" && "min-h-sidebar-button",
				props.action.style === "secondary" &&
					clsx("text-primary", !props.action.static && "&hover:bg-gray-18 &hover:text-primary"),
				props.action.style === "tertiary" && "text-tertiary &hover:text-primary",
				props.action.style === "wide" &&
					"bg-sidebar_button_primary &hover:bg-sidebar_button_primary_hover",
				props.action.style === "focus" && "bg-gray-82 &hover:bg-gray-86 text-gray-10",
				props.action.style === "primary" && " bg-sidebar_button_primary ",
				props.action.style === "primary" &&
					!props.action.disabled &&
					"&hover:bg-sidebar_button_primary_hover text-primary",
				(props.action.disabled || props.action.static) && " !cursor-default",
				props.action.class,
			)}
			style={stylex(
				c.fullWidth,
				c.row,
				c.justifyBetween,
				c.alignCenter,
				c.height(styles().height),
				c.py(12),
				c.px(c.getSidebarPadding(responsive())),
				props.action.style === "secondary" && c.borderBottom(`1px solid ${c.colors.border}`),
				props.action.style === "secondary" &&
					props.first &&
					c.borderTop(`1px solid ${c.colors.border}`),
			)}
		>
			<div
				style={stylex(c.column, c.grow)}
				class={clsx(
					shrinkSide() === "left" ? "shrink-1" : "shrink-0",
					"transition-opacity opacity-100",
					(props.action.disabled || props.action.off) && "opacity-50",
				)}
			>
				<CMText
					style={stylex(
						props.action.style === "focus" ? c.weightBold : c.weightSemiBold,
						c.fontSize(14),
						styles().textStyles,
					)}
				>
					{props.action.text}
				</CMText>
				<Show when={props.action.subtext}>
					<>
						<Spacer height={4} />
						<CMText
							style={stylex(
								c.fg(styles().subtextColor),
								props.action.style === "focus" ? c.weightBold : c.weightSemiBold,
								c.fontSize(14),
							)}
						>
							{props.action.subtext}
						</CMText>
					</>
				</Show>
			</div>
			<Spacer width={16} />
			<Show when={props.action.right}>
				<div
					style={stylex(c.row, c.center)}
					class={clsx(
						"transition-opacity opacity-100 text-xs",
						(props.action.disabled || props.action.off) && "opacity-50",
						shrinkSide() === "left" ? "shrink-0" : "shrink-1",
					)}
				>
					{typeof props.action.right === "string" ? (
						<CMText
							style={stylex(
								props.action.style === "focus"
									? c.fg(c.colors.text.inverseSecondary)
									: c.fg(c.colors.text.secondary),
								props.action.style === "focus" ? c.weightBold : c.weightSemiBold,
								props.action.style === "secondary" &&
									stylex(c.fg(c.colors.text.tertiary), c.weightBold),
								c.fontSize(12),
							)}
						>
							{props.action.right}
						</CMText>
					) : (
						<p style={stylex(c.fg(c.colors.text.tertiary))}>{props.action.right}</p>
					)}
				</div>
			</Show>
		</div>
	);
};

export const SidebarActions = (props: {
	actions: SidebarAction[];
	header?: JSXElement;
}) => {
	return (
		<div style={stylex(c.column, c.fullWidth)}>
			{props.header}
			<Intersperse
				each={() => filter(props.actions, (a) => !a.hidden)}
				separator={(action, _i, nextAction) => {
					const highestPrecedenceAction = createMemo(() => {
						const types: SidebarAction["style"][] = [
							"wide",
							"focus",
							"primary",
							"secondary",
							"tertiary",
						];
						return types.find((t) => t === action().style || t === nextAction().style);
					});
					return (
						<Switch>
							<Match when={action().style === "primary" && nextAction().style === "secondary"}>
								<Spacer height={12} />
							</Match>
							<Match when={highestPrecedenceAction() === "wide"}>
								<Spacer height={20} />
							</Match>
							<Match when={highestPrecedenceAction() === "focus"}>
								<Spacer height={10} />
							</Match>
							<Match when={highestPrecedenceAction() === "primary"}>
								<Spacer height={10} />
							</Match>
							<Match when={highestPrecedenceAction() === "secondary"}>
								<Spacer height={0} />
							</Match>
							<Match when={highestPrecedenceAction() === "tertiary"}>
								<Spacer height={0} />
							</Match>
						</Switch>
					);
				}}
			>
				{(a, i) => (
					<SidebarFullWidthButton
						action={a()}
						first={i === 0 || props.actions[i - 1].style !== "secondary"}
					/>
				)}
			</Intersperse>
		</div>
	);
};

export const SeeMoreActions = (props: {
	onClick: () => void;
	text: string;
}) => {
	const responsive = useResponsiveV2();
	return (
		<div style={stylex(c.row, c.px(c.getSidebarPadding(responsive())))} class={clsx("pt-4")}>
			<div style={stylex(c.pb(2))} class="cursor-pointer" use:onClick={props.onClick}>
				<CMText class="text-tertiary &hover:text-primary text-xs font-semibold transition-colors">
					{props.text}
				</CMText>
			</div>
		</div>
	);
};

export const SidebarSectionHeader = (props: {
	text: string;
	right?: JSXElement;
	border?: boolean;
	class?: string;
}) => {
	const responsive = useResponsiveV2();
	return (
		<div
			class="font-medium"
			style={stylex(
				c.row,
				c.justifyBetween,
				c.alignCenter,
				c.px(c.getSidebarPadding(responsive())),
				c.pb(12),
				props.border && c.borderBottom(`1px solid ${c.colors.border}`),
			)}
		>
			<CMText style={stylex(c.fontSize(14))} class={clsx("text-tertiary", props.class)}>
				{props.text}
			</CMText>
			{props.right}
		</div>
	);
};

export const useBiggestGapActions = (props?: {
	source?: "nearest" | "biggest" | "both";
}): SidebarAction[] => {
	const source = props?.source ?? "both";
	const nearestMiss = () => BROWSING_STATE().getNearestMiss();
	const currentEpd = () => BROWSING_STATE().chessboard.getCurrentEpd();
	const mode = useMode();
	const getBiggestGapAction = (miss: RepertoireMiss, text: string, right: string | null) => {
		const line = Line.fromPgn(miss.lines[0]);

		return {
			onPress: () => {
				quick((s) => {
					trackEvent(`${mode()}.added_line_state.next_gap`);
					UI().backTo(RepertoireBuilder);
					if (UI().mode !== "build") {
						BROWSING_STATE().startBrowsing(
							s.repertoireState.browsingState.activeRepertoireId!,
							"build",
						);
					}
					s.repertoireState.browsingState.chessboard.playLine(line, {
						animated: true,
					});
				});
			},
			text: text,
			right: right,
			style: "secondary",
		} as SidebarAction;
	};
	const actions = createMemo(() => {
		const actions: SidebarAction[] = [];
		const nearest = nearestMiss();
		const biggest = BROWSING_STATE().getBiggestMiss();
		const same = biggest?.epd === nearest?.epd;
		const getEcoNames = (miss: RepertoireMiss) => {
			const line = Line.fromPgn(miss.lines[0]);
			return useLineEcoCode(line)();
		};
		const nearestEcoName = nearest && getEcoNames(nearest);
		const biggestEcoName = biggest && getEcoNames(biggest);
		const sameName = nearestEcoName?.name === biggestEcoName?.name;
		if (biggest && (source === "biggest" || source === "both") && biggest.epd !== currentEpd()) {
			actions.push(
				getBiggestGapAction(
					biggest,
					`Go to ${isMobile() ? "" : "your"} biggest gap`,
					!biggestEcoName ? "" : sameName ? biggestEcoName.lastVariation : biggestEcoName.name,
				),
			);
		}
		if (
			nearest &&
			(!same || actions.length === 0) &&
			(source === "nearest" || source === "both") &&
			nearest.epd !== currentEpd()
		) {
			actions.unshift(
				getBiggestGapAction(
					nearest,
					"Go to your next gap",
					!nearestEcoName ? "" : sameName ? nearestEcoName.lastVariation : nearestEcoName.name,
				),
			);
		}
		return actions;
	});
	return actions();
};
