import { isNil } from "lodash-es";
import { Show, createMemo } from "solid-js";
import { Spacer } from "~/components/Space";
import type { Uuid } from "~/types/Uuid";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { pluralize } from "~/utils/pluralize";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { AdvancedRepertoireSettings } from "./AdvancedRepertoireSettings";
import { CMText } from "./CMText";
import { CoverageBar } from "./CoverageBar";
import { PlansBrowser } from "./PlansBrowser";
import { PreReview } from "./PreReview";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { RepertoireCompletion } from "./RepertoireCompletion";
import { ReviewText } from "./ReviewText";
import { type SidebarActionGroup, SidebarActionGroups } from "./SidebarActionGroups";
import { useBiggestGapActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { ChooseImportSourceOnboarding } from "./SidebarOnboarding";
import { SidebarTemplate } from "./SidebarTemplate";
import { TemplateSidebarView } from "./TemplateSidebarView";

export const RepertoireOverview: EnrichedComponent<null> = () => {
	onBack(() => {
		quick((_s) => {
			animateSidebar("left");
		});
	});

	const side = () => BROWSING_STATE().activeSide;
	const textClasses = "text-primary font-semibold";
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const biggestMiss = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!]?.biggestMiss;
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const numMovesDueFromHere = () =>
		REPERTOIRE_STATE().numMovesDueFromEpd[activeRepertoireId()!]?.[START_EPD];
	const earliestDueDate = () =>
		REPERTOIRE_STATE().earliestReviewDueFromEpd[activeRepertoireId()!]?.[START_EPD];

	const empty = () => numMoves() === 0;
	const functionallyEmpty = () => REPERTOIRE_STATE().numMyEnabledMoves[activeRepertoireId()!] === 0;
	const groups = createMemo(() => {
		const groups: SidebarActionGroup[] = [];
		if (!functionallyEmpty()) {
			groups.push({
				title: undefined,
				actions: [
					{
						onPress: () => {
							quick((_s) => {
								trackEvent("side_overview.start_review");
								UI().pushView(PreReview, {
									props: { repertoireId: activeRepertoireId() ?? null },
								});
							});
						},
						right: (
							<ReviewText date={earliestDueDate()} numDue={numMovesDueFromHere()} overview={true} />
						),
						text: <CMText class={clsx(textClasses)}>Practice moves</CMText>,
						style: "primary",
					},
					{
						onPress: () => {
							quick((_s) => {
								trackEvent("side_overview.view_plans_and_model_games");
								UI().pushView(PlansBrowser);
								BROWSING_STATE().startBrowsing(activeRepertoireId()!, "plans_and_model_games");
							});
						},
						text: <CMText class={clsx(textClasses)}>View plans and model games</CMText>,
						style: "primary",
					},
				],
			});
		}
		const missActions = useBiggestGapActions();
		groups.push({
			title: "Edit this repertoire",
			actions: [
				...missActions,
				{
					onPress: () => {
						quick((_s) => {
							UI().pushView(RepertoireBuilder);
							BROWSING_STATE().startBrowsing(activeRepertoireId()!, "build");
							if (empty()) {
								trackEvent("side_overview.start_building");
							} else {
								trackEvent("side_overview.keep_building");
							}
							return;
						});
					},
					right: pluralize(numMoves(), "move"),
					text: (
						<CMText class={clsx(textClasses)}>
							{empty()
								? "Start building your repertoire"
								: isNil(biggestMiss())
									? "Browse / add new moves"
									: "Browse / edit something else"}
						</CMText>
					),
					style: "secondary",
				},
			],
		});
		groups.push({
			title: "More options",
			actions: [
				...[
					{
						text: empty() ? "Import" : "Import / export",
						onPress: () => {
							quick((_s) => {
								if (empty()) {
									trackEvent("side_overview.import");
									quick((_s) => {
										UI().pushView(ChooseImportSourceOnboarding, {
											props: { side: side() },
										});
									});
									return;
								}
								UI().pushView(TemplateSidebarView, {
									props: {
										header: "Import / export",
										actions: [
											{
												onPress: () => {
													trackEvent("side_overview.import");
													quick((_s) => {
														UI().pushView(ChooseImportSourceOnboarding, {
															props: { side: side() },
														});
													});
												},
												text: <CMText class={clsx(textClasses)}>Import</CMText>,
												right: null,
												style: "secondary",
											},
											{
												onPress: () => {
													quick((s) => {
														trackEvent("side_overview.export");
														s.repertoireState.exportPgn(repertoire().id);
													});
												},
												text: <CMText class={clsx(textClasses)}>Export to PGN</CMText>,
												right: null,
												style: "secondary",
											},
										],
									},
								});
							});
						},
						style: "secondary" as const,
					},
					{
						text: "Repertoire settings",
						onPress: () => {
							quick((_s) => {
								UI().pushView(AdvancedRepertoireSettings);
							});
						},
						style: "secondary" as const,
					},
				],
			],
		});

		return groups;
	});
	return (
		<SidebarTemplate header={`${repertoire()?.name} repertoire`} actions={[]} bodyPadding={false}>
			<div class="padding-sidebar">
				<RepertoireCompletion repertoireId={activeRepertoireId()} />
			</div>
			<Spacer height={48} />
			<SidebarActionGroups groups={groups()} />
		</SidebarTemplate>
	);
};

export const CoverageAndBar = (props: {
	repertoireId: Uuid;
	hideBar?: boolean;
}) => {
	const progressState = () =>
		REPERTOIRE_STATE().browsingState.repertoireProgressState[props.repertoireId!];

	return (
		<div style={stylex(c.row, c.alignCenter)}>
			<CMText class="text-secondary font-semibold text-xs">
				{progressState().completed ? (
					<>Completed</>
				) : (
					<>{Math.round(progressState().percentComplete * 100)}% Complete</>
				)}
			</CMText>
			<Show when={!props.hideBar}>
				<>
					<Spacer width={8} />
					<div style={stylex(c.height(4), c.width(80), c.row)}>
						<CoverageBar repertoireId={props.repertoireId} />
					</div>
				</>
			</Show>
		</div>
	);
};

registerViewMode(RepertoireOverview, "side_overview");
