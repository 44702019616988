import { isEmpty } from "lodash-es";
import type { Accessor } from "solid-js";
import type { SidebarAction } from "~/components/SidebarActions";
import { animateSidebar } from "~/components/SidebarContainer";
import { TargetCoverageReachedView } from "~/components/TargetCoverageReachedView";
import { APP_STATE, UI, quick } from "./app_state";

export const usePlansAction = (): Accessor<SidebarAction | undefined> => {
	const hasPlans = () => {
		const browsingState = APP_STATE().repertoireState.browsingState;
		const repertoireState = APP_STATE().repertoireState;
		return !isEmpty(
			repertoireState.positionReports[browsingState.activeRepertoireId!]?.[
				browsingState.chessboard.getCurrentEpd()
			]?.plans,
		);
	};
	return () => {
		if (!hasPlans()) {
			return;
		}
		return {
			onPress: () => {
				quick((s) => {
					const bs = s.repertoireState.browsingState;
					animateSidebar("right");
					UI().pushView(TargetCoverageReachedView);
					bs.showPlansState.coverageReached = false;
				});
			},
			text: "How to play from here",
			style: "secondary",
		} as SidebarAction;
	};
};
