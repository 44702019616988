import { filter, isEmpty, map, mapValues, some, sum, values } from "lodash-es";
import { createMemo } from "solid-js";
import { Repertoire } from "~/types/Repertoire";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { Uuid } from "~/types/Uuid";
import type { EnrichedComponent } from "~/types/View";
import { APP_STATE, BROWSING_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { isMobile } from "~/utils/isMobile";
import { pluralize } from "~/utils/pluralize";
import { Quiz, countQueue } from "~/utils/queues";
import { bySide } from "~/utils/repertoire";
import { COMMON_MOVES_CUTOFF } from "~/utils/review";
import { c } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { Label } from "./Label";
import { RepertoireBrowser } from "./RepertoireBrowser";
import { RepertoireReview } from "./RepertoireReview";
import { ReviewText } from "./ReviewText";
import type { SidebarAction } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";

export const PreReview: EnrichedComponent<{ repertoireId: Uuid | null }> = (props) => {
	const numMovesDueByRepertoire = () =>
		mapValues(
			REPERTOIRE_STATE().repertoires ?? {},
			(repertoire) => REPERTOIRE_STATE().numMovesDueFromEpd[repertoire.id]?.[START_EPD] ?? 0,
		);
	const repertoire = () =>
		props.repertoireId ? REPERTOIRE_STATE().repertoires?.[props.repertoireId] : undefined;
	const queue = createMemo(() => {
		return APP_STATE().repertoireState.reviewState.buildQueue({
			repertoireId: repertoire()?.id,
			filter: "due",
		});
	});
	const isQuizzing = () => {
		return !isEmpty(APP_STATE().repertoireState.reviewState.activeQueue);
	};
	const totalDue = () => REPERTOIRE_STATE().totalMovesDue ?? 0;
	const actions = createMemo(() => {
		const actions: SidebarAction[] = [];
		// todo: this could be more performant
		const difficultDueCount = countQueue(
			filter(queue(), (m) =>
				some(Quiz.getMoves(m), (m) => SpacedRepetitionStatus.isDifficult(m.srs)),
			),
		);
		const difficultCount = (
			repertoire()
				? Repertoire.getAllEnabledRepertoireMoves(repertoire())
				: Repertoire.getAllEnabledRepertoiresMoves(APP_STATE().repertoireState.repertoires)
		).filter((m) => m.mine && SpacedRepetitionStatus.isDifficult(m.srs)).length;
		const due = repertoire() ? numMovesDueByRepertoire()[repertoire()!.id] : totalDue();
		const anyDue = due > 0;
		if (isQuizzing()) {
			actions.push({
				onPress: () => {
					quick(() => {
						trackEvent("pre_review.resume");
						animateSidebar("right");
						UI().pushView(RepertoireReview);
					});
				},
				text: (
					<div class={clsx("row items-center")}>
						<p class={clsx()}>Continue your practice session</p>
					</div>
				),
				right: (
					<ReviewText
						class="!text-primary"
						icon="fa fa-forward"
						iconColor={c.colors.text.secondary}
						descriptor="Left"
						numDue={APP_STATE().repertoireState.reviewState.activeQueue.length}
					/>
				),
				style: "primary",
			});
		}
		if (due > 0) {
			actions.push({
				onPress: () => {
					quick((s) => {
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							filter: "recommended",
						});
						trackEvent("pre_review.common_moves");
					});
				},
				text: "Recommended moves",
				right: <ReviewText numDue={Math.min(COMMON_MOVES_CUTOFF, due)} />,
				style: "secondary",
			});
		}
		if (due > COMMON_MOVES_CUTOFF) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.all_due");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							filter: "due",
						});
					});
				},
				text: (
					<div class={clsx("row items-center")}>
						<p class={clsx()}>
							Everything that's due {isMobile() ? "" : "for review"}
							{!isQuizzing && <Label>Recommended</Label>}
						</p>
					</div>
				),
				right: <ReviewText numDue={due} />,
				style: "secondary",
			});
		}
		if (difficultDueCount > 0) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.difficult_due");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							filter: "difficult-due",
						});
					});
				},
				text: "Just the moves I often get wrong",
				right: <ReviewText numDue={difficultDueCount} />,
				style: "secondary",
			});
		}
		if (!anyDue) {
			const earlyQueue = APP_STATE().repertoireState.reviewState.buildQueue({
				repertoireId: repertoire()?.id,
				filter: "early",
			});
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.early");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							filter: "early",
						});
					});
				},
				text: "Moves that will be due soon",
				right: `${pluralize(earlyQueue.length, "move")}`,
				style: "secondary",
			});
		}
		if (!anyDue && difficultCount > 0) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.difficult");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							filter: "difficult",
						});
					});
				},
				text: "Just the moves I often get wrong",
				right: `${pluralize(difficultCount, "move")}`,
				style: "secondary",
			});
		}
		const myMoves = APP_STATE().repertoireState.numMyEnabledMoves;
		const numMyMoves = props.repertoireId
			? myMoves[props.repertoireId]
			: sum(values(bySide((side) => myMoves[side])));
		if (numMyMoves > due) {
			actions.push({
				onPress: () => {
					quick((s) => {
						trackEvent("pre_review.all");
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: repertoire()?.id,
							filter: "all",
						});
					});
				},
				text: repertoire() ? `My entire ${repertoire()!.name} repertoire` : "My entire repertoire",
				right: `${numMyMoves} moves`,
				style: "secondary",
			});
		}
		actions.push({
			onPress: () => {
				quick((_s) => {
					trackEvent("pre_review.specific");
					if (props.repertoireId) {
						UI().clearViews();
						animateSidebar("right");
						UI().pushView(RepertoireBrowser);
						BROWSING_STATE().startBrowsing(props.repertoireId, "browse");
					} else {
						UI().pushView(ChooseSideForSpecificPractice);
					}
				});
			},
			text: "A specific opening I've added",
			right: <i class="fa fa-arrow-right" />,
			style: "secondary",
		});
		return actions;
	});
	return (
		<SidebarTemplate
			header={"What would you like to practice?"}
			actions={actions()}
			bodyPadding={true}
		/>
	);
};

// registerViewMode(PreReview, "review");

export const ChooseSideForSpecificPractice = () => {
	return (
		<SidebarTemplate
			header={"Which repertoire do you want to practice?"}
			actions={map(REPERTOIRE_STATE().repertoiresList, (repertoire) => ({
				style: "primary",
				onPress: () => {
					quick((_s) => {
						UI().clearViews();
						UI().pushView(RepertoireBrowser);
						BROWSING_STATE().startBrowsing(repertoire.id, "browse");
					});
				},
				text: repertoire.name,
			}))}
			bodyPadding={true}
		/>
	);
};
