import type { Uuid } from "~/types/Uuid";
import { BROWSING_STATE, REPERTOIRE_STATE } from "~/utils/app_state";
import { c, stylex } from "~/utils/styles";
import { CoverageBar } from "./CoverageBar";
import { Spacer } from "./Space";

export const RepertoireCompletion = (props: { repertoireId: Uuid }) => {
	const progressState = () => BROWSING_STATE().repertoireProgressState[props.repertoireId];
	const repertoire = () => REPERTOIRE_STATE().repertoires?.[props.repertoireId];
	return (
		<div>
			<p class="body-text">
				Your {repertoire()?.name || ""} repertoire is{" "}
				<b style={stylex(c.fg(c.gray[80]), c.weightSemiBold)}>
					{Math.round(progressState()?.percentComplete * 100)}%
				</b>{" "}
				complete.
			</p>
			<Spacer height={16} />
			<div class="h-2 rounded-full overflow-hidden">
				<CoverageBar repertoireId={props.repertoireId} large />
			</div>
		</div>
	);
};
