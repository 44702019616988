import type { AxiosResponse } from "axios";
import type { OpeningsReport } from "~/types/OpeningsReport";
import { type AppState, quick } from "./app_state";
import { createChessboardInterface } from "./chessboard_interface";
import client from "./client";
import type { RepertoireState } from "./repertoire_state";

export type OpeningsReportState = ReturnType<typeof getInitialOpeningsReportState>;

type Stack = [OpeningsReportState, RepertoireState, AppState];

export const getInitialOpeningsReportState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.repertoireState.openingReportsState, s.repertoireState, s]);
		});
	};
	const initialState = {
		chessboard: createChessboardInterface()[1],
		openingsReports: null as OpeningsReport[] | null,
		fetchOpeningsReport: () =>
			set(([_s, _gs]) => {
				client.get("/api/v2/openings_report").then((resp: AxiosResponse<OpeningsReport[]>) => {
					set(([s]) => {
						s.openingsReports = resp.data;
					});
				});
			}),
	};
	return initialState;
};
