import { addWeeks, format, isEqual, startOfWeek } from "date-fns";
import { entries, forEach, reverse, sortBy } from "lodash-es";
import { type Accessor, For, createMemo } from "solid-js";
import { ChessGame } from "~/types/ChessGame";
import { EcoCode } from "~/types/EcoCode";
import type { EnrichedComponent } from "~/types/View";
import { MODEL_GAMES_STATE, REPERTOIRE_STATE, quick } from "~/utils/app_state";
import { isMobile } from "~/utils/isMobile";
import { registerViewMode } from "~/utils/register_view_mode";
import { SidebarActions, SidebarSectionHeader } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const ModelGameHistory: EnrichedComponent<Record<string, never>> = () => {
	const historyGroups: Accessor<{ title: string; games: ChessGame[] }[]> = createMemo(() => {
		const history = MODEL_GAMES_STATE().history;
		if (!history) {
			return [];
		}
		const byStartOfWeek: Record<string, ChessGame[]> = {};
		forEach(history, (game) => {
			const date = new Date(game.createdAt);
			const atStartOfWeek = startOfWeek(date);
			const startOfWeekString = atStartOfWeek.toISOString();
			if (!byStartOfWeek[startOfWeekString]) {
				byStartOfWeek[startOfWeekString] = [];
			}
			byStartOfWeek[startOfWeekString].push(game);
		});
		const groups = reverse(sortBy(entries(byStartOfWeek), ([key]) => new Date(key)));
		return groups.map(([key, games]) => {
			const date = new Date(key);
			let title = key;
			if (isEqual(date, startOfWeek(new Date()))) {
				title = "This week";
			} else if (isEqual(date, startOfWeek(addWeeks(new Date(), -1)))) {
				title = "Last week";
			} else {
				title = `Week of ${format(date, "MMM do")}`;
			}
			return { title, games };
		});
	});
	return (
		<SidebarTemplate
			header={"Your model games history"}
			headerRight={<div class="row gap-2"></div>}
			actions={[]}
		>
			<SidebarActions
				actions={[
					{
						text:
							MODEL_GAMES_STATE().dailyGame?.reviewed === false
								? "Play through today's game"
								: "Play through model game",
						style: "primary",
						onPress: () => {
							quick((s) => {
								if (MODEL_GAMES_STATE().dailyGame?.reviewed === false) {
									s.repertoireState.modelGamesState.reviewGame(MODEL_GAMES_STATE().dailyGame!);
								} else {
									s.repertoireState.modelGamesState.reviewNewGame();
								}
							});
						},
					},
				]}
			/>
			<Spacer between={["actions", "actions"]} />
			<For each={historyGroups()}>
				{(group) => (
					<>
						<SidebarSectionHeader text={group.title} />
						<SidebarActions
							actions={group.games.map((game) => {
								const eco = REPERTOIRE_STATE().getLastEcoCode(game.epds);
								return {
									text: ChessGame.formatChessGameTitle(game, isMobile()),
									right: eco ? (
										<p class="truncate font-semibold">
											{EcoCode.getAppropriateEcoName(eco.fullName)[0]}
										</p>
									) : undefined,
									style: "secondary",
									onPress: () => {
										quick((s) => {
											animateSidebar("right");
											s.repertoireState.modelGamesState.reviewGame(game);
										});
									},
								};
							})}
						/>
						<Spacer between={["actions", "actions"]} />
					</>
				)}
			</For>
		</SidebarTemplate>
	);
};

registerViewMode(ModelGameHistory, "model_games");
